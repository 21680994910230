@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.my_package {
  margin-top: 45px;
  padding: 0px 25px;
  .my_package_inner {
    .my_package_card_status {
      display: block;

      @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 575px) {
        margin-bottom: 20px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 575px) {
          row-gap: 10px;
        }

        li {
          flex: 0 0 33.33%;
          max-width: 33.33%;
          text-align: center;

          @media only screen and (max-width: 575px) {
            flex: 0 0 50%;
            max-width: 50%;
          }

          a {
            font-size: 16px;
            color: $white-color;
            border-bottom: 1px solid #e4e4e4;
            border-radius: 0px;
            padding: 5px 20px;
            text-decoration: none;

            &.active {
              background-color: transparent;
              color: $primary-color;
              border-bottom: 1px solid $primary-color;
            }
          }
        }
      }
    }

    .tab-pane {
      &.fade {
        display: none;
      }

      &.fade.active {
        display: block;
      }

      .my_package_cards {
        margin: 0 -18px;

        .my_package_card {
          margin-bottom: 36px;
          padding: 0 18px;

          .my_package_card_inner {
            box-shadow: $drop-shadow;
            border-radius: 20px;
            padding: 20px 28px;
            height: 100%;

            .my_package_card_title {
              padding-bottom: 23px;
              margin-bottom: 14px;
              border-bottom: 1px solid #e4e4e4;

              h2 {
                text-transform: uppercase;
                font-weight: 500;
              }
            }

            .my_package_detail {
              .my_package_list {
                .my_package_item {
                  margin-bottom: 19px;
                  font-size: 16px;
                  font-weight: normal;
                  color: $black-color;

                  span {
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
