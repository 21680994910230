@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.dinesh{
  color:red;
}
.personal_info_section {
  padding: 50px 0px;

  .protokoll_title {
    padding: 41px 0px 43px 0px;

    @media only screen and (max-width: 767px) {
      padding: 25px 0px 15px;
    }

    a {
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.2;
      font-size: 24px;

      @media only screen and (max-width: 1199px) {
        font-weight: 700;
        max-width: calc(100% - 81px);
        font-size: 20px;
      }

      &:hover {
        color: $default-color;
      }
    }
  }
  
  .personal_info_title {
    padding: 0px 0px 43px 0px;

    h2 {
      text-transform: uppercase;
      color: $white-color;
    }

    @media only screen and (max-width: 767px) {
      padding: 0px 0px 34px 0px;
    }
  }

  .personal_info_card {
    padding: 60px;
    border-radius: 20px;
    box-shadow: $drop-shadow;
    background-color: $dark-bg;

    @media only screen and (max-width: 767px) {
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }

    .personal_info_card_inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -40px;

      @media only screen and (max-width: 991px) {
        margin: 0px;
      }

      .info_name_img {
        flex: 0 0 211px;
        max-width: 211px;
        padding: 0 40px;

        @media only screen and (max-width: 991px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 24px;
          padding: 0px;
        }

        .info_img {
          text-align: center;

          .input-group-text {
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;

            img {
              width: 130px;
              height: 130px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }

        .info_name {
          text-align: center;
          margin-top: 16px;

          h3 {
            line-height: 25px;
            font-weight: 500;
            color: $white-color;
          }
        }
      }

      .info_form {
        flex: 0 0 calc(100% - 211px);
        max-width: calc(100% - 211px);
        padding: 0 40px;

        @media only screen and (max-width: 991px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0px;
        }

        form {
          row-gap: 24px;

          .form-label {
            margin-bottom: 10px;
            font-family: "Brother 1816";
            font-size: 16px;
            line-height: 23px;
            color: $form-lable-color;
          }

          .form-control {
            font-family: "Brother 1816";
            border: 1px solid $black-color;
            border-radius: 10px;
            height: 50px;
            font-size: 16px;
            line-height: 23px;
            color: $white-color;
            background-color: $black-color;

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          textarea {
            &.form-control {
              height: 150px;

              @media only screen and (max-width: 767px) {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}
