@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.news_detail_section {
    padding: 50px 0px 50px;

    .news_detail_inner {
        box-shadow: $drop-shadow;
        background-color: $dark-bg;
        padding: 60px;
        border-radius: 20px;

        @media only screen and (max-width: 1199px) {
                padding: 30px;
            }

        .date_title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 30px;

            @media only screen and (max-width: 767px) {
                justify-content: center;
            }

            .date {
                p {
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            .title {
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    left: -15px;
                    width: 1px;
                    height: 100%;
                    content: "";
                    display: block;
                    background-color: $dark-bg;
                }

                h2 {
                    font-weight: 500;
                }
            }
        }

        .news_detail_img {
            margin: 30px 0px;
            img {
                width: 100%;
                height: 35vw;
                object-fit: cover;
                object-position: center;
            }
        }

        .news_detail{
            h3{
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            p{
                font-size: 18px;
                line-height: 1.4;
                margin-bottom: 10px;
            }
            ul{
                padding-left: 2rem;
                list-style-type: disc;
                margin-bottom: 10px;
                font-family: "Brother 1816";
            }
        }
    }
}