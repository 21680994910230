@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.teacher_course_section {
    padding: 0px 0px 50px;

    .user_detail {
        position: relative;
        border-bottom: 1px solid $border-color;

        .teacher_course_title {
            padding: 24px 0px 24px 0px;

            @media only screen and (max-width: 767px) {
                padding: 25px 0px 15px;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 500;

                @media only screen and (max-width: 1199px) {
                    font-weight: 700;
                    max-width: calc(100% - 81px);
                }
            }
        }
    }

    .teacher_course {
        margin-top: 40px;

        @media only screen and (max-width: 767px) {
            margin-top: 30px;
        }

        @media only screen and (max-width: 575px) {
            margin-top: 20px;
        }

        .teacher_course_inner {

            .teacher_course_card_status {
                display: block;
                margin-bottom: 40px;

                @media only screen and (max-width: 767px) {
                    margin-bottom: 30px;
                }

                @media only screen and (max-width: 575px) {
                    margin-bottom: 20px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    row-gap: 10px;

                    li {
                        flex: 0 0 25%;
                        max-width: 25%;
                        text-align: center;

                        @media only screen and (max-width: 991px) {
                            flex: 0 0 50%;
                            max-width: 50%;
                        }


                        a {
                            font-size: 16px;
                            color: $white-color;
                            border-bottom: 1px solid #e4e4e4;
                            border-radius: 0px;
                            padding: 5px 20px;
                            text-decoration: none;

                            &.active {
                                background-color: transparent;
                                color: $primary-color;
                                border-bottom: 1px solid $primary-color;

                            }
                        }
                    }
                }
            }

            .tab-pane {
                &.fade {
                    display: none;
                }

                &.fade.active {
                    display: block;
                }

                .teacher_course_cards {
                    margin: 0 -18px;

                    .teacher_course_card {
                        margin-bottom: 36px;
                        padding: 0 18px;

                        .teacher_course_card_inner {
                            box-shadow: $drop-shadow;
                            background-color: $dark-bg;
                            border-radius: 20px;
                            padding: 20px 28px;
                            height: 100%;

                            .teacher_course_card_title {
                                padding-bottom: 23px;
                                margin-bottom: 14px;
                                border-bottom: 1px solid #E4E4E4;

                                h2 {
                                    text-transform: uppercase;
                                    font-weight: 500;
                                }
                            }

                            .teacher_course_detail {
                                .teacher_course_list {
                                    .teacher_course_item {
                                        margin-bottom: 19px;
                                        font-size: 16px;
                                        font-weight: normal;
                                        color: $white-color;
                                        display: flex;
                                        flex-wrap: wrap;
                                        align-items: flex-start;
                                        gap: 10px;

                                        @media only screen and (max-width: 767px) {
                                            margin-bottom: 14px;
                                            font-size: 14px;
                                        }

                                        span {
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }

                            .teacher_course_card_detail {
                                margin-bottom: 20px;

                                p {
                                    font-size: 18px;
                                    line-height: 1.4;

                                    @media only screen and (max-width: 767px) {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}