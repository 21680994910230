@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.loyalty_card_section {
    padding: 0px 0px 50px;

    .user_detail {

        .notifications_title {
            padding: 41px 0px 24px 0px;

            @media only screen and (max-width: 767px) {
                padding: 36px 0px;
                border-bottom: 1px solid #E4E4E4;
                margin-bottom: 30px;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 500;

                @media only screen and (max-width: 1199px) {
                    font-weight: 700;
                }
            }
        }
    }

    .loyalty_card_inner {
        margin-top: 20px;

        .loyalty_card_blocks {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            align-items: center;
            justify-content: center;

            .loyalty_card_block {
                a {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: $drop-shadow;
                    background-color: $white-color;
                    border: 1px solid $border-color;
                    border-radius: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px;

                    &:hover {
                        background-color: $primary-color;
                        border: 1px solid $primary-color;
                        color: $white-color;
                    }

                    &.activated {
                        background-color: green;
                        border: 1px solid green;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

.loyalty_modal {
    .modal {
        .modal-dialog {
            max-width: 65%;

            @media only screen and (max-width: 1199px) {
                max-width: 75%;
            }

            @media only screen and (max-width: 767px) {
                max-width: 95%;
            }

            .modal-header {
                border-bottom: 0px;

                a {
                    width: 24px;
                    height: 24px;

                    &.btn-close {
                        opacity: 1;
                        background: transparent;
                        background-image: none;
                    }
                }
            }

            .modal-body {
                form {
                    .form-label {
                        margin-bottom: 10px;
                        font-family: 'Brother 1816';
                        font-size: 16px;
                        line-height: 23px;
                        color: $form-lable-color;
                    }

                    .form-control {
                        font-family: 'Brother 1816';
                        border: 1px solid #EEEEEE;
                        border-radius: 10px;
                        height: 50px;
                        font-size: 16px;
                        line-height: 23px;
                        color: $black-color;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }
            }

            .modal-footer {
                border-top: 0px;
            }
        }
    }
}