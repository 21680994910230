@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.dashboard {
  .dashboard_inner {
    padding: 10px 0px;

    .showcase_card_booking {
      padding: 50px 0px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 -21px;

      @media only screen and (max-width: 1800px) {
        row-gap: 42px;
      }

      @media only screen and (max-width: 1199px) {
        margin: 0;
        row-gap: 25px;
      }

      @media only screen and (max-width: 767px) {
        padding: 12px 0px 40px;
      }

      .rank_card_column {
        flex: 0 0 33.85%;
        max-width: 33.85%;
        padding: 0 21px;

        @media only screen and (max-width: 1800px) {
          flex: 0 0 45%;
          max-width: 45%;
        }

        @media only screen and (max-width: 1199px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
        }

        @media only screen and (max-width: 767px) {
          order: 1;
        }

        .rank_card {
          height: 300px;

          @media only screen and (max-width: 767px) {
            height: auto;
          }

          .rank_card_inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            background-color: $dark-bg;
            padding: 84px 34px;
            height: 100%;
            border-radius: 20px;
            box-shadow: $drop-shadow;

            @media only screen and (max-width: 1199px) {
              max-width: 473px;
            }

            @media only screen and (max-width: 767px) {
              margin: 0 auto;
              align-items: flex-start;
              padding: 30px 28px 46px;
            }

            .ranker_img {
              flex: 0 0 55.19%;
              max-width: 55.19%;

              @media only screen and (max-width: 1400px) {
                flex: 0 0 55%;
                max-width: 55%;
              }

              @media only screen and (max-width: 767px) {
                flex: 0 0 51.7%;
                max-width: 51.7%;
              }
            }

            .ranker_name {
              flex: 0 0 42%;
              max-width: 42%;

              @media only screen and (max-width: 1400px) {
                flex: 0 0 44%;
                max-width: 44%;
              }

              @media only screen and (max-width: 767px) {
                flex: 0 0 45%;
                max-width: 45%;
                padding-top: 10px;
              }

              h2 {
                color: $white-color;
                font-weight: 500;
                line-height: 34px;
                word-wrap: break-word;

                @media only screen and (max-width: 1400px) {
                  font-size: 22px;
                  line-height: 30px;
                }

                @media only screen and (max-width: 767px) {
                  font-size: 20px;
                  line-height: 28px;
                }

                @media only screen and (max-width: 767px) {
                  font-size: 18px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }

      .occasion_card_column {
        flex: 0 0 43.1%;
        max-width: 43.1%;
        padding: 0 21px;

        @media only screen and (max-width: 1800px) {
          flex: 0 0 55%;
          max-width: 55%;
        }

        @media only screen and (max-width: 1199px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
        }

        @media only screen and (max-width: 767px) {
          order: 3;
        }

        .occasion_card {
          height: 300px;

          @media only screen and (max-width: 767px) {
            height: auto;
          }

          .occasion_card_inner {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            border-radius: 20px;
            box-shadow: $drop-shadow;
            background-color: $dark-bg;

            @media only screen and (max-width: 1199px) {
              max-width: 614px;
            }

            @media only screen and (max-width: 767px) {
              margin: 0 auto;
            }

            .occasion_img {
              flex: 0 0 49%;
              max-width: 49%;

              @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
              }

              img {
                width: 100%;
                height: 300px;
                object-fit: cover;

                @media only screen and (max-width: 767px) {
                  height: 220px;
                  object-position: top;
                }
              }
            }

            .occasion_date_title {
              flex: 0 0 51%;
              max-width: 51%;
              padding: 30px 0px 84px;
              position: relative;

              @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 16px 0px 98px;
              }

              .occasion_title {
                padding: 0px 48px 0px 38px;

                @media only screen and (max-width: 1600px) {
                  padding: 0px 40px 0px 30px;
                }

                h2 {
                  font-weight: 500;
                  line-height: 34px;
                  word-wrap: break-word;
                  color: $white-color;

                  @media only screen and (max-width: 1400px) {
                    font-size: 20px;
                    line-height: 30px;
                  }

                  span {
                    display: block;
                  }
                }
              }

              .occasion_date {
                padding: 20px 48px 20px 38px;
                background-color: #f8f8f8;
                position: absolute;
                bottom: 0;
                width: 100%;

                p {
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 1.2;
                  color: $secondary-color;
                }
              }
            }
          }
        }
      }

      .booking_btn_column {
        flex: 0 0 21.7%;
        max-width: 21.7%;
        padding: 0 21px;

        @media only screen and (max-width: 1800px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @media only screen and (max-width: 767px) {
          order: 2;
          border-bottom: 1px solid #eeeeee;
          padding-bottom: 40px;
          margin-bottom: 15px;
        }

        .booking_btn {
          @media only screen and (max-width: 767px) {
            text-align: center;
          }

          .course_btn {
            margin-bottom: 10px;

            a {
              font-size: 14px;
              line-height: 1;
              font-weight: normal;
              border-radius: 50px;
              background-color: $secondary-color;
              padding: 15px 25px;
              color: $white-color;
            }
          }

          .absence_btn {
            a {
              font-size: 14px;
              line-height: 1;
              font-weight: normal;
              border-radius: 50px;
              background-color: $secondary-color;
              padding: 15px 25px;
              color: $white-color;
            }
          }
        }
      }
    }

    .menu_card_group {
      padding: 50px 0px;

      @media only screen and (max-width: 767px) {
        padding: 40px 0px;
      }

      .row {
        margin: 0 -19px;
        row-gap: 38px;

        @media only screen and (max-width: 767px) {
          margin: 0 -6px;
          row-gap: 12px;
        }

        .menu_card {
          flex: 0 0 16.6666666667%;
          max-width: 16.6666666667%;
          padding: 0 19px;

          @media only screen and (max-width: 1800px) {
            flex: 0 0 20%;
            max-width: 20%;
          }

          @media only screen and (max-width: 1600px) {
            flex: 0 0 25%;
            max-width: 25%;
          }

          @media only screen and (max-width: 1400px) {
            flex: 0 0 33.33%;
            max-width: 33.33%;
          }

          @media only screen and (max-width: 991px) {
            flex: 0 0 50%;
            max-width: 50%;
          }

          @media only screen and (max-width: 767px) {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 5px;
          }

          @media only screen and (max-width: 425px) {
            flex: 0 0 50%;
            max-width: 50%;
          }

          .menu_card_inner {
            // background-color: $light-gray-bg;
            background-color: $dark-bg;
            display: block;
            height: 100%;
            border-radius: 20px;
            text-align: center;
            padding: 57px 18px 43px;
            text-decoration: none;
            color: inherit;

            @media only screen and (max-width: 767px) {
              padding: 30px 6px 26px;
            }

            &:hover {
              transition: 0.5s all ease-in-out;
              background-color: $primary-color;

              .menu_card_icon {
                img {
                  filter: brightness(0) invert(1);
                  transition: 0.5s all ease-in-out;
                }
              }

              h2 {
                color: $white-color;
                transition: 0.5s all ease-in-out;
              }
            }

            .menu_card_icon {
              margin: 0 auto;
              width: 49px;
              height: 49px;

              @media only screen and (max-width: 767px) {
                width: 25px;
                height: 25px;
              }

              img {
                object-fit: contain;
                filter: brightness(0) invert(1);

                @media only screen and (max-width: 767px) {
                  width: 100%;
                  height: 25px;
                }
              }
            }

            h2 {
              margin-top: 26px;
              font-size: 24px;
              line-height: 34px;
              font-weight: 500;
              text-transform: uppercase;
              word-wrap: break-word;
              color: $white-color;

              @media only screen and (max-width: 1800px) {
                font-size: 22px;
                line-height: 30px;
              }

              @media only screen and (max-width: 1199px) {
                font-size: 18px;
                line-height: 22px;
              }

              @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
