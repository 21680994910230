@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.apply_course_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;

    .apply_course_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .apply_course_inner {
    .apply_course_card {
      @media only screen and (max-width: 767px) {
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
      }

      .apply_course_card_inner {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1607843137);
        border-radius: 20px;
        padding: 20px 28px;
        height: 100%;

        .apply_course_card_title {
          padding-bottom: 23px;
          margin-bottom: 14px;
          border-bottom: 1px solid #e4e4e4;

          h2 {
            text-transform: uppercase;
            font-weight: 500;
          }
        }

        .apply_course_detail {
          .apply_course_list {
            .apply_course_item {
              margin-bottom: 19px;
              font-size: 16px;
              font-weight: normal;
              color: $white-color;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              gap: 10px;

              @media only screen and (max-width: 767px) {
                margin-bottom: 14px;
                font-size: 14px;
              }

              span {
                text-transform: uppercase;
              }
            }
          }

          h3 {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #e4e4e4;
            font-weight: 500;
            text-transform: uppercase;
          }

          .space_for_male,
          .space_for_female {
            margin-bottom: 20px;

            p {
              margin-bottom: 10px;
              text-transform: uppercase;
            }

            .select_slot {
              form {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .form-check {
                  .form-check-input {
                    width: 25px;
                    height: 25px;
                    background-color: green;
                    border: none;
                    border-radius: 0px;
                    cursor: pointer;

                    &:checked {
                      border: none;
                      border-radius: 0px;
                      outline: none;
                      box-shadow: none;
                      background-color: $primary-color;
                      background-image: none;
                    }

                    &:focus {
                      border: none;
                      outline: none;
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .apply_course_btn {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;

      a {
        text-transform: uppercase;
      }
    }
  }

  .apply_data_table {
    .table {
      margin-top: 50px;
      margin-bottom: 0px;

      @media only screen and (max-width: 1199px) {
        margin-top: 30px;
      }

      .table-group-divider {
        border-top: 2px solid $primary-color;
      }

      th {
        white-space: nowrap;
        text-align: center;
      }

      tbody {
        tr {
          td {
            text-align: center;

            &:nth-child(2) {
              display: flex;
              justify-content: space-around;
              align-items: center;
            }

            &:first-child {
              text-align: left;
            }
          }

          .date {
            display: flex;
            gap: 10px;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            img {
              width: 16px;
              height: 16px;
              object-fit: contain;
            }

            span {
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
