.page-wrapper {
  margin-left: 320px;
  position: relative;
  height: calc(100vh - 91px);
  overflow: auto;

  @media only screen and (max-width: 1400px) {
    margin-left: 260px;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0px;
    height: calc(100vh - 136px);
  }

  .scanner video {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back_btn {
    p {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      padding: 12px 32px;
      cursor: pointer;

      img {
        transform: rotate(180deg);
      }
    }
  }
}
