@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.department_section {
    padding: 0px 0px 50px;

    .user_detail {
        position: relative;

        .department_title {
            padding: 41px 0px 43px 0px;

            @media only screen and (max-width: 767px) {
                padding: 25px 0px 15px;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 500;

                @media only screen and (max-width: 1199px) {
                    font-weight: 700;
                    max-width: calc(100% - 81px);
                }
            }
        }
    }

    .department_inner {
        .department_table {

            .table {

                .table-group-divider {
                    border-top: 2px solid $primary-color;
                }

                th {
                    white-space: nowrap;
                    color: $white-color;
                        background-color: $dark-bg;

                    &.bg_primary {
                        background-color: $primary-color;
                        color: $white-color;
                        border: 1px solid $primary-color;
                    }
                }

                tbody {
                    tr {
                        td {
                            vertical-align: middle;
                            color: $white-color;
                                background-color: $dark-bg;

                            &:nth-child(5) {
                                width: 100px;
                            }

                            .form-check {
                                padding: 0;
                                text-align: center;

                                .form-check-input {
                                    width: 20px;
                                    height: 20px;
                                    float: none;
                                    margin: 0;
                                    border-radius: 0px;

                                    &:checked {
                                        background-color: $primary-color;
                                        border-color: $primary-color;
                                        border-radius: 0px;
                                    }

                                    &:focus {
                                        border-color: rgba(0, 0, 0, 0.25);
                                        box-shadow: none;
                                        outline: none;
                                    }
                                }
                            }

                            .btn_primary {
                                padding: 10px 20px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}