$default-color: #1c1c1c;
$primary-color: #cf2856;
$secondary-color: #1c79b4;
$light-gray-color: #909090;
$white-color: #ffffff;
$black-color: #000000;
$dark-bg: #32363a;
$light-bg: #f7f7f7;
$light-gray-bg: #e8e8e8;
$navbar-bg: #222324;
$nabar-border-color: #373737;
$border-color: #d6d6d6;
$btn-primary-hover: rgba(207, 40, 86, 0.7);
$btn-secondary-hover: rgba(28, 121, 180, 0.7);
$form-lable-color: #909090;
$drop-shadow: 0px 0px 20px #00000029;
// $drop-shadow: 0px 0px 20px #ffffff29;
