@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.news_listing_section {
    .user_detail {

        .news_listing_title {
            padding: 41px 0px 24px 0px;

            @media only screen and (max-width: 767px) {
                padding: 36px 0px;
                border-bottom: 1px solid #E4E4E4;
                margin-bottom: 30px;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 500;

                @media only screen and (max-width: 1199px) {
                    font-weight: 700;
                }
            }
        }
    }

    .news_listing_inner {
        padding: 0px 0px 10px;

        .news_listing_cards {
            padding: 0px 0px 50px;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -21px;
            row-gap: 42px;

            @media only screen and (max-width: 1199px) {
                margin: 0;
                row-gap: 24px;
            }

            @media only screen and (max-width: 991px) {
                padding: 12px 0px 40px;
            }

            .news_listing_card_column {
                padding: 0 21px;

                @media only screen and (max-width: 991px) {
                    padding: 0px;
                }

                .news_listing_card {
                    height: 300px;

                    @media only screen and (max-width: 991px) {
                        height: auto;
                    }

                    .news_listing_card_inner {
                        display: flex;
                        flex-wrap: wrap;
                        overflow: hidden;
                        border-radius: 20px;
                        box-shadow: $drop-shadow;
                        background-color: $dark-bg;

                        @media only screen and (max-width: 991px) {
                            margin: 0 auto;
                        }

                        .news_listing_img {
                            flex: 0 0 49%;
                            max-width: 49%;

                            @media only screen and (max-width: 991px) {
                                flex: 0 0 100%;
                                max-width: 100%;
                            }

                            img {
                                width: 100%;
                                height: 300px;
                                object-fit: cover;

                                @media only screen and (max-width: 991px) {
                                    height: 45vw;
                                    object-position: center;
                                }

                                @media only screen and (max-width: 575px) {
                                    height: 50vw;
                                }
                            }

                        }

                        .news_listing_date_title {
                            flex: 0 0 51%;
                            max-width: 51%;
                            padding: 30px 0px 84px;
                            position: relative;

                            @media only screen and (max-width: 991px) {
                                flex: 0 0 100%;
                                max-width: 100%;
                                padding: 16px 0px 80px;
                            }

                            .news_listing_title {
                                padding: 0px 30px 0px 30px;

                                @media only screen and (max-width: 1600px) {
                                    padding: 0px 20px 0px 20px;
                                }

                                h3 {
                                    font-weight: 500;
                                    line-height: 30px;
                                    font-size: 20px;
                                    word-wrap: break-word;
                                    text-transform: uppercase;

                                    @media only screen and (max-width: 1400px) {
                                        font-size: 18px;
                                        line-height: 26px;
                                    }

                                    span {
                                        display: block;
                                    }
                                }
                            }

                            .news_listing_des {
                                padding: 10px 30px 0px 30px;

                                @media only screen and (max-width: 1600px) {
                                    padding: 10px 20px 0px 20px;
                                }

                                p {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 4;
                                    line-clamp: 4;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;

                                    @media only screen and (max-width: 575px) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .news_listing_date {
                                position: absolute;
                                bottom: 0;
                                width: 100%;

                                a {
                                    background-color: #f8f8f8;
                                    padding: 20px 30px 20px 30px;
                                    font-size: 20px;
                                    font-weight: 700;
                                    line-height: 1.2;
                                    color: $secondary-color;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-wrap: wrap;

                                    &:hover {
                                        background-color: $primary-color;
                                        color: $white-color;

                                        img {
                                            filter: brightness(0) invert(1);
                                            transform: scale(1.5);
                                        }
                                    }

                                    img {
                                        max-width: 20px;
                                        object-fit: contain;
                                        transition: 0.5s all ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
