@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .notifications_title_datetime_inner {
          .notifications_title {
            margin-bottom: 20px;

            @media only screen and (max-width: 991px) {
              margin-bottom: 10px;
            }

            h2 {
              font-weight: 500;
            }
          }

          .notifications_datetime {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $border-color;

            @media only screen and (max-width: 991px) {
              margin-bottom: 10px;
              padding-bottom: 10px;
            }

            ul {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              column-gap: 20px;

              @media only screen and (max-width: 575px) {
                column-gap: 10px;
              }

              li {
                font-size: 18px;
                font-weight: normal;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 10px;
                line-height: normal;

                @media only screen and (max-width: 575px) {
                  font-size: 16px;
                  column-gap: 5px;
                }

                img {
                  width: 20px;

                  @media only screen and (max-width: 575px) {
                    width: 18px;
                  }
                }
              }
            }
          }

          .notifications_des {
            p {
              font-size: 18px;
              line-height: 1.4;

              @media only screen and (max-width: 575px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.notifications_section {
  padding: 0px 0px 50px;

  .user_detail {
    .notifications_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .notifications_listing {
    .notifications_inner {
      .notifications_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 20px;
        margin-bottom: 40px;

        @media only screen and (max-width: 575px) {
          column-gap: 0px !important;
          row-gap: 10px;
          justify-content: flex-end !important;
        }

        @media only screen and (max-width: 425px) {
          justify-content: center !important;
        }

        .select_all_btn {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          column-gap: 20px;

          @media only screen and (max-width: 575px) {
            column-gap: 2px !important;
            justify-content: space-between !important;
            width: 100% !important;
          }

          .select_btn,
          .all_btn {
            a {
              @media only screen and (max-width: 991px) {
                padding: 10px 24px;
                font-size: 12px;
              }
            }
          }
        }

        .delete_btn_icon {
          display: flex;
          gap: 20px;

          .delete_btn {
            a {
              img {
                width: 35px;
                object-fit: contain;

                @media only screen and (max-width: 991px) {
                  width: 25px !important;
                }
              }
            }
          }
        }
      }

      .notifications_cards {
        &.row {
          row-gap: 30px;

          @media only screen and (max-width: 1199px) {
            row-gap: 20px;
          }

          .notifications_card {
            .notifications_card_inner {
              border-radius: 20px;
              box-shadow: $drop-shadow;
              background-color: $dark-bg;
              padding: 30px;

              @media only screen and (max-width: 575px) {
                padding: 20px;
              }

              .checkbox_title_datetime {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin: 0 -20px;

                @media only screen and (max-width: 1199px) {
                  margin: 0 -15px;
                }

                @media only screen and (max-width: 575px) {
                  margin: 0 -12px;
                }

                .notifications_checkbox {
                  flex: 0 0 70px;
                  max-width: 70px;
                  padding: 0 20px;

                  @media only screen and (max-width: 1199px) {
                    flex: 0 0 60px;
                    max-width: 60px;
                    padding: 0 15px;
                  }

                  @media only screen and (max-width: 575px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0 12px;
                    margin-bottom: 10px;
                  }

                  .form-check {
                    padding-left: 0px;

                    .form-check-input {
                      width: 30px;
                      height: 30px;
                      margin: 0;
                      border-radius: 0px;

                      &:checked {
                        background-color: $primary-color;
                        border-color: $primary-color;
                        border-radius: 0px;
                      }

                      &:focus {
                        border-color: $border-color;
                        box-shadow: none;
                        outline: none;
                      }
                    }
                  }
                }

                .notifications_title_datetime {
                  flex: 0 0 calc(100% - 70px);
                  max-width: calc(100% - 70px);
                  padding: 0 20px;
                  position: relative;

                  @media only screen and (max-width: 1199px) {
                    flex: 0 0 calc(100% - 60px);
                    max-width: calc(100% - 60px);
                    padding: 0 15px;
                  }

                  @media only screen and (max-width: 575px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0 12px;
                  }

                  &::after {
                    position: absolute;
                    content: "";
                    width: 0px;
                    height: 100%;
                    background-color: $border-color;
                    top: 0px;
                    left: 0px;

                    @media only screen and (max-width: 575px) {
                      display: none;
                    }
                  }

                  .notifications_title_datetime_inner {
                    .notifications_title {
                      margin-bottom: 20px;

                      @media only screen and (max-width: 991px) {
                        margin-bottom: 10px;
                      }

                      h2 {
                        font-weight: 500;
                      }
                    }

                    .notifications_datetime {
                      padding-bottom: 20px;
                      margin-bottom: 20px;
                      border-bottom: 1px solid $border-color;

                      @media only screen and (max-width: 991px) {
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                      }

                      ul {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        column-gap: 20px;

                        @media only screen and (max-width: 575px) {
                          column-gap: 10px;
                        }

                        li {
                          font-size: 18px;
                          font-weight: normal;
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          column-gap: 10px;
                          line-height: normal;
                          color: $white-color;

                          @media only screen and (max-width: 575px) {
                            font-size: 16px;
                            column-gap: 5px;
                          }

                          img {
                            width: 20px;

                            @media only screen and (max-width: 575px) {
                              width: 18px;
                            }
                          }
                        }
                      }
                    }

                    .notifications_des {
                      p {
                        font-size: 18px;
                        line-height: 1.4;

                        @media only screen and (max-width: 575px) {
                          font-size: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
