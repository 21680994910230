@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.task_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;

    .task_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .task_inner {
    .task_table {
      .table {
        margin-bottom: 50px;

        @media only screen and (max-width: 1199px) {
          margin-bottom: 30px;
        }

        .table-group-divider {
          border-top: 2px solid $primary-color;

          @media only screen and (max-width: 1199px) {
            padding: 20px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 20px;
          }

          tr {
            @media only screen and (max-width: 1199px) {
              flex: 0 0 calc(50% - 10px);
              max-width: calc(50% - 10px);
            }

            @media only screen and (max-width: 991px) {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }

        th {
          white-space: nowrap;
          color: $white-color;
          background-color: $dark-bg;

          &.bg_primary {
            background-color: $primary-color;
            color: $white-color;
            border: 1px solid $primary-color;
          }
        }

        tbody {
          tr {
            td {
              vertical-align: middle;
              color: $white-color;
                background-color: $dark-bg;

              @media only screen and (max-width: 1199px) {
                padding: 0.5rem !important;
              }

              &:nth-child(5) {
                width: 100px;

                @media only screen and (max-width: 1440px) {
                  width: 100%;
                }
              }

              .attendsmn {
                display: inline-block;
              }

              .check_admin,
              .check_status {
                width: 20px;
                height: 20px;
                float: none;
                margin: 0;
                border-radius: 0px;

                @media only screen and (max-width: 1440px) {
                  position: relative;
                  bottom: -5px;
                }

                &:checked {
                  background-color: $primary-color;
                  border-color: $primary-color;
                  border-radius: 0px;
                }

                &:focus {
                  border-color: rgba(0, 0, 0, 0.25);
                  box-shadow: none;
                  outline: none;
                }
              }

              .btn_primary {
                padding: 10px 20px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    table thead {
      display: none;
    }

    table tbody {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;
    }

    table tbody tr,
    table tbody td {
      display: block;
      border: 0;
    }

    table tbody td:before {
      width: auto;
      display: inline-block;
      text-transform: capitalize;
      font-weight: normal;
      margin-right: 10px;
      font-weight: 500;
    }

    table tbody td {
      &:nth-child(1) {
        &::before {
          content: "Task Name :";
        }
      }

      &:nth-child(2) {
        &::before {
          content: "Responsible Teacher :";
        }
      }

      &:nth-child(3) {
        &::before {
          content: "Student :";
        }
      }

      &:nth-child(4) {
        &::before {
          content: "Repeat :";
        }
      }

      &:nth-child(5) {
        &::before {
          content: "Notes :";
        }
      }

      &:nth-child(6) {
        &::before {
          content: "Status :";
        }
      }

      &:nth-child(7) {
        &::before {
          content: "Deadline Date :";
        }
      }

      &:nth-child(8) {
        &::before {
          content: "Warning Date :";
        }
      }

      &:nth-child(9) {
        &::before {
          content: "Telephone Detail :";
        }
      }

      &:nth-child(10) {
        &::before {
          content: "Quality check :";
        }
      }

      &:nth-child(11) {
        &::before {
          content: "Completed By Teacher :";
        }
      }

      &:nth-child(12) {
        &::before {
          content: "Quality status :";
        }
      }

      &:nth-child(13) {
        &::before {
          content: "CEO status :";
        }
      }

      &:nth-child(14) {
        &::before {
          content: "Quality Reject :";
        }
      }

      &:nth-child(15) {
        &::before {
          content: "CEO Reject :";
        }
      }

      &:nth-child(16) {
        &::before {
          content: "Close Task? :";
        }
      }

      &:nth-child(17) {
        &::before {
          content: "Task Process :";
        }
      }

      &:nth-child(18) {
        &::before {
          content: "Notes :";
        }
      }
    }

    table tbody td.status {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    table tbody tr {
      position: relative;
      border: 1px solid #E7E7E7;
      border-width: 1px;
      padding: 16px;
    }

    td:empty {
      display: none;
    }
  }
}