@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.place_absence_section {
  padding: 0px 0px 50px;

  .user_detail {
    .place_absence_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .place_absence {
    .place_absence_inner {
      .place_absence_cards {
        margin: 0 -18px 45px;
        padding-bottom: 25px;
        border-bottom: 1px solid $border-color;

        .place_absence_card {
          margin-bottom: 36px;
          padding: 0 18px;

          .place_absence_card_inner {
            box-shadow: $drop-shadow;
            background-color: $dark-bg;
            border-radius: 20px;
            padding: 20px 28px;
            height: 100%;

            .place_absence_card_title {
              padding: 0 0 23px 0;
              margin-bottom: 14px;
              border-bottom: 1px solid #e4e4e4;
              display: flex;
              justify-content: space-between;

              h2 {
                flex: 0 0 calc(100% - 40px);
                max-width: calc(100% - 40px);
                text-transform: uppercase;
                font-weight: 500;
              }

              .form-check-input {
                flex: 0 0 20px;
                max-width: 20px;
                width: 100%;
                height: 20px;
                margin: 2px 0px 0 0px;
                border-radius: 0px;

                &:checked {
                  background-color: $primary-color;
                  border-color: $primary-color;
                  border-radius: 0px;
                }

                &:focus {
                  border-color: rgba(0, 0, 0, 0.25);
                  box-shadow: none;
                  outline: none;
                }
              }
            }

            .place_absence_detail {
              display: flex;
              flex-wrap: wrap;

              .form-check {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 18px;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;

                .form-check-label {
                  font-size: 18px;
                  font-weight: normal;
                  color: $white-color;
                  flex: 0 0 calc(100% - 32px);
                  max-width: calc(100% - 32px);
                }

                .form-check-input {
                  flex: 0 0 20px;
                  max-width: 20px;
                  width: 100%;
                  height: 20px;
                  float: none;
                  margin: 4px 12px 0 0px;
                  border-radius: 0px;

                  &:checked {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    border-radius: 0px;
                  }

                  &:focus {
                    border-color: rgba(0, 0, 0, 0.25);
                    box-shadow: none;
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }

      .place_absence_calender {
        .row {
          row-gap: 25px;

          .form-label {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            display: block;
            color: $white-color;

            span {
              color: $primary-color;
            }
          }

          .form-control {
            border: 1px solid #d6d6d6;
            width: 100%;
            padding: 13px 25px;
            background-color: $dark-bg;
            border-radius: 50px;
            color: $white-color;
            font-size: 18px;
            line-height: 1.2;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:focus {
              border: 1px solid #d6d6d6;
              box-shadow: none;
              outline: none;
            }
          }

          .arrow_img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            img {
              width: 50px;
              height: 50px;

              @media only screen and (max-width: 991px) {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
}

.courses_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;
    border-bottom: 1px solid $border-color;

    .courses_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .courses {
    margin-top: 40px;

    .courses_cards {
      margin: 0 -18px;

      .courses_card {
        margin-bottom: 36px;
        padding: 0 18px;

        .courses_card_inner {
          box-shadow: $drop-shadow;
          background-color: $dark-bg;
          border-radius: 20px;
          padding: 20px 28px;
          height: 100%;

          .courses_card_title {
            padding-bottom: 23px;
            margin-bottom: 14px;
            border-bottom: 1px solid #e4e4e4;

            h2 {
              text-transform: uppercase;
              font-weight: 500;
            }
          }

          .courses_detail {
            .courses_list {
              .courses_item {
                margin-bottom: 19px;
                font-size: 16px;
                font-weight: normal;
                color: $white-color;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 10px;

                @media only screen and (max-width: 767px) {
                  margin-bottom: 14px;
                  font-size: 14px;
                }

                span {
                  text-transform: uppercase;
                }

                .available_seat {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  gap: 10px;
                  margin-top: 2px;

                  .female_span,
                  .male_span {
                    width: 15px;
                    height: 15px;
                    background-color: $primary-color;
                    display: inline-block;
                    top: 2px;
                    position: relative;

                    &.space_remain {
                      background-color: green;
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }

          .courses_card_detail {
            margin-bottom: 20px;

            p {
              font-size: 18px;
              line-height: 1.4;

              @media only screen and (max-width: 767px) {
                font-size: 16px;
              }
            }
          }

          .course_btns {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }

    .courses_inner {
      .courses_card_status {
        display: block;
        margin-bottom: 40px;

        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }

        @media only screen and (max-width: 575px) {
          margin-bottom: 20px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          row-gap: 10px;

          li {
            flex: 0 0 25%;
            max-width: 25%;
            text-align: center;
            color: $white-color;

            @media only screen and (max-width: 991px) {
              flex: 0 0 50%;
              max-width: 50%;
            }

            a {
              font-size: 16px;
              color: $white-color;
              border-bottom: 1px solid #e4e4e4;
              border-radius: 0px;
              padding: 5px 20px;
              text-decoration: none;

              &.active {
                background-color: transparent;
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
              }
            }
          }
        }
      }

      .tab-pane {
        &.fade {
          display: none;
        }

        &.fade.active {
          display: block;
        }

        .courses_cards {
          margin: 0 -18px;

          .courses_card {
            margin-bottom: 36px;
            padding: 0 18px;

            .courses_card_inner {
              box-shadow: $drop-shadow;
              border-radius: 20px;
              padding: 20px 28px;
              height: 100%;

              .courses_card_title {
                padding-bottom: 23px;
                margin-bottom: 14px;
                border-bottom: 1px solid #e4e4e4;

                h2 {
                  text-transform: uppercase;
                  font-weight: 500;
                }
              }

              .courses_detail {
                .courses_list {
                  .courses_item {
                    margin-bottom: 19px;
                    font-size: 16px;
                    font-weight: normal;
                    color: $white-color;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    gap: 10px;

                    @media only screen and (max-width: 767px) {
                      margin-bottom: 14px;
                      font-size: 14px;
                    }

                    span {
                      text-transform: uppercase;
                    }

                    .available_seat {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      gap: 10px;
                      margin-top: 2px;

                      .female_span,
                      .male_span {
                        width: 15px;
                        height: 15px;
                        background-color: $primary-color;
                        display: inline-block;
                        top: 2px;
                        position: relative;

                        &.space_remain {
                          background-color: green;
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
              }

              .courses_card_detail {
                margin-bottom: 20px;

                p {
                  font-size: 18px;
                  line-height: 1.4;

                  @media only screen and (max-width: 767px) {
                    font-size: 16px;
                  }
                }
              }

              .course_btns {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 20px;
              }
            }
          }
        }
      }
    }
  }

  .accordion {
    margin-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }

    .accordion-item {
      border: 1px solid $primary-color;
      margin-bottom: 20px;
      box-shadow: $drop-shadow;
      border-radius: 5px;

      .accordion-header {
        .accordion-button {
          border-radius: 5px;
          font-weight: 500;
          background-color: $dark-bg;
          color: $white-color;

          &:not(.collapsed) {
            color: $white-color;
            background-color: $primary-color;
            border-radius: 5px 5px 0px 0px;

            &::after {
              filter: brightness(0) invert(1);
            }
          }

          &::after {
            background-image: url(../../../assets/images/icons/arrow-down.svg);
            background-size: auto;
            width: 14px;
            height: 8px;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          background-color: $black-color;
          border-radius: 0 0 5px 5px;
          .courses {
            margin-top: 20px;
          }
        }
      }
    }
  }
}