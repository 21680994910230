@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.login_section {
  min-height: calc(100vh - 100px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 0px;

  @media only screen and (max-width: 1400px) {
    padding: 60px 0px;
  }

  @media only screen and (max-width: 991px) {
    padding: 30px 0px;
  }

  @media only screen and (max-width: 767px) {
    min-height: calc(100vh - 80px);
    padding: 30px 0px;
  }

  .container {
    text-align: center;

    @media only screen and (min-width: 1600px) {
      max-width: 1400px;
    }

    .login_row {
      margin: 0 -95px;

      @media only screen and (max-width: 1400px) {
        margin: 0 -30px;
      }

      @media only screen and (max-width: 991px) {
        row-gap: 115px;
      }

      .login_img_column {
        flex: 0 0 780px;
        max-width: 780px;
        padding: 0 95px;

        @media only screen and (max-width: 1400px) {
          flex: 0 0 550px;
          max-width: 550px;
          padding: 0 30px;
        }

        @media only screen and (max-width: 991px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .login_img {
          @media only screen and (max-width: 991px) {
            text-align: center;
          }
        }
      }

      .login_content_column {
        flex: 0 0 calc(100% - 780px);
        max-width: calc(100% - 780px);
        padding: 0 95px;

        @media only screen and (max-width: 1400px) {
          flex: 0 0 calc(100% - 550px);
          max-width: calc(100% - 550px);
          padding: 0 30px;
        }

        @media only screen and (max-width: 991px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .signInHeading {
          max-width: 454px;
          margin: 0 auto;
          text-align: center;

          h1 {
            font-weight: 500;
            margin-bottom: 32px;
            font-size: 32px;

            @media only screen and (max-width: 767px) {
              margin-bottom: 22px;
              font-size: 20px;
            }
          }
        }

        .login_form {
          margin-top: 40px;

          .custom-select {
            position: relative;
            display: inline-block;
            display: block;
            width: 100%;
            max-width: 500px;
            margin: 0 auto 20px;

            @media only screen and (max-width: 767px) {
              margin: 0 auto 10px;
            }

            .dropdown-toggle {
              border: 1px solid $border-color;
              width: 100%;
              padding: 18px 25px;
              background-color: $dark-bg;
              border-radius: 50px;
              color: $white-color;
              font-size: 14px;
              line-height: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &.show {
                border-radius: 20px 20px 0px 0px;

                &::after {
                  transform: rotate(180deg);
                }
              }

              &::after {
                border: 0px;
                margin: 0px;
                background-image: url(../../assets/images/icons/arrow-down.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
                width: 16px;
                height: 10px;
                vertical-align: unset;
                transition: 0.5s all ease-in-out;
              }

              &:active {
                border-color: $border-color;
                background-color: $dark-bg;
                color: $white-color;
              }
            }

            .dropdown-menu {
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              z-index: 1000;
              display: none;
              padding: 0;

              &.show {
                display: block;
                border-radius: 0 0 20px 20px;
                border-color: $border-color;
                background-color: $dark-bg;
              }

              li {
                &:last-child {
                  .dropdown-item {
                    &:hover {
                      border-radius: 0 0 20px 20px;
                    }
                  }
                }
              }

              .dropdown-item {
                color: $white-color;
                font-size: 14px;
                line-height: 1;
                padding: 14px 25px;

                &:hover {
                  background-color: $primary-color;
                  color: $white-color;
                }
              }
            }
          }

          .login_input {
            position: relative;
            display: inline-block;
            display: block;
            border: 1px solid $border-color;
            width: 100%;
            max-width: 500px;
            margin: 0 auto 20px;
            max-width: 500px;
            padding: 18px 25px;
            background-color: $dark-bg !important;
            border-radius: 50px;
            color: $white-color !important;
            font-size: 14px;
            line-height: 1;
            outline: none;

            &:active {
              background-color: $dark-bg !important;
            }
          }

          .login_btn {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;

            .btn_primary {
              border-radius: 50px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 12px;
              padding: 15px 25px;
            }
          }
        }

        .changeSchool {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .schoolChangeLink:nth-child(2){
            margin-top: 5px;
          }
          .schoolChangeLink {
            width: 100%;
            max-width: 500px;
            margin-top: 20px;
            text-align: right;

            a {
              text-decoration: none;
              color: $primary-color;

              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    text-align: center;
    padding: 35px 30px;

    @media only screen and (max-width: 767px) {
      padding: 25px 30px;
    }

    p {
      color: $light-gray-color;
      font-size: 14px;
      line-height: 30px;
    }
  }
}
