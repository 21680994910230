@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.be_happy_section {
    padding: 50px 0px;

    .be_happy_title {
        padding: 0px 0px 43px 0px;

        h2 {
            text-transform: uppercase;
        }

        @media only screen and (max-width: 767px) {
            padding: 0px 0px 34px 0px;
        }
    }

    .be_happy_card {
        padding: 60px;
        border-radius: 20px;
        box-shadow: $drop-shadow;
        background-color: $dark-bg;

        @media only screen and (max-width: 767px) {
            padding: 0px;
            border-radius: 0px;
            box-shadow: none;
        }

        .be_happy_card_inner {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -40px;

            @media only screen and (max-width: 991px) {
                margin: 0px;
            }

            .be_happy_form {

                form {
                    row-gap: 24px;

                    .form-label {
                        margin-bottom: 10px;
                        font-family: 'Brother 1816';
                        font-size: 16px;
                        line-height: 23px;
                        color: $white-color;
                    }

                    .form-control {
                        font-family: 'Brother 1816';
                        border: 1px solid #EEEEEE;
                        border-radius: 10px;
                        height: 50px;
                        font-size: 16px;
                        line-height: 23px;
                        color: $white-color;
                        background-color: $black-color;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    .custom-select {
                        position: relative;
                        display: inline-block;
                        display: block;
                        width: 100%;
                        margin: 0 auto;

                        @media only screen and (max-width: 767px) {
                            margin: 0 auto 10px;
                        }

                        .dropdown-toggle {
                            border: 1px solid #eeeeee;
                            width: 100%;
                            padding: 17px 25px;
                            background-color: $black-color;
                            border-radius: 10px;
                            color: $white-color;
                            font-size: 14px;
                            line-height: 1;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            &.show {
                                border-radius: 10px 10px 0px 0px;

                                &::after {
                                    transform: rotate(180deg);
                                }
                            }

                            &::after {
                                border: 0px;
                                margin: 0px;
                                background-image: url(../../assets/images/icons/arrow-down.svg);
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                content: "";
                                width: 16px;
                                height: 10px;
                                vertical-align: unset;
                                transition: 0.5s all ease-in-out;
                            }

                            &:active {
                                border-color: $border-color;
                                background-color: $black-color;
                                color: $white-color;
                            }
                        }

                        .dropdown-menu {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                            z-index: 1000;
                            display: none;
                            padding: 0;

                            &.show {
                                display: block;
                                border-radius: 0 0 10px 10px;
                                border-color: #eeeeee;
                                background-color: $black-color;
                            }

                            li {
                                &:last-child {
                                    .dropdown-item {
                                        &:hover {
                                            border-radius: 0 0 10px 10px;
                                        }
                                    }
                                }
                            }

                            .dropdown-item {
                                color: $white-color;
                                font-size: 14px;
                                line-height: 1;
                                padding: 14px 25px;

                                &:hover {
                                    background-color: $primary-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }

                .greetings {
                    margin: 50px 0px 20px;

                    @media only screen and (max-width: 1199px) {
                        margin: 30px 0px 20px;
                    }

                    p:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}