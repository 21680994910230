@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.courses_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;
    border-bottom: 1px solid $border-color;

    .courses_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .courses {
    margin-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }

    .courses_inner {
      .courses_card_status {
        display: block;
        margin-bottom: 40px;

        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }

        @media only screen and (max-width: 575px) {
          margin-bottom: 20px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          row-gap: 10px;

          li {
            flex: 0 0 25%;
            max-width: 25%;
            text-align: center;

            @media only screen and (max-width: 991px) {
              flex: 0 0 50%;
              max-width: 50%;
            }

            a {
              font-size: 16px;
              color: $black-color;
              border-bottom: 1px solid #e4e4e4;
              border-radius: 0px;
              padding: 5px 20px;
              text-decoration: none;

              &.active {
                background-color: transparent;
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
              }
            }
          }
        }
      }

      .tab-pane {
        &.fade {
          display: none;
        }

        &.fade.active {
          display: block;
        }

        .courses_cards {
          margin: 0 -18px;

          .courses_card {
            margin-bottom: 36px;
            padding: 0 18px;

            .courses_card_inner {
              box-shadow: $drop-shadow;
              border-radius: 20px;
              padding: 20px 28px;
              height: 100%;

              .courses_card_title {
                padding-bottom: 23px;
                margin-bottom: 14px;
                border-bottom: 1px solid #e4e4e4;

                h2 {
                  text-transform: uppercase;
                  font-weight: 500;
                }
              }

              .courses_detail {
                .courses_list {
                  .courses_item {
                    margin-bottom: 19px;
                    font-size: 16px;
                    font-weight: normal;
                    color: $black-color;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    gap: 10px;

                    @media only screen and (max-width: 767px) {
                      margin-bottom: 14px;
                      font-size: 14px;
                    }

                    span {
                      text-transform: uppercase;
                    }

                    .available_seat {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      gap: 10px;
                      margin-top: 2px;

                      .female_span,
                      .male_span {
                        width: 15px;
                        height: 15px;
                        background-color: $primary-color;
                        display: inline-block;
                        top: 2px;
                        position: relative;

                        &.space_remain {
                          background-color: green;
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
              }

              .courses_card_detail {
                margin-bottom: 20px;

                p {
                  font-size: 18px;
                  line-height: 1.4;

                  @media only screen and (max-width: 767px) {
                    font-size: 16px;
                  }
                }
              }

              .course_btns {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 20px;
              }
            }
          }
        }
      }
    }
  }

  .accordion {
    margin-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }

    .accordion-item {
      border: 1px solid $primary-color;
      margin-bottom: 20px;
      box-shadow: $drop-shadow;
      border-radius: 5px;

      .accordion-header {
        .accordion-button {
          border-radius: 5px;
          font-weight: 500;

          &:not(.collapsed) {
            color: $white-color;
            background-color: $primary-color;
            border-radius: 5px 5px 0px 0px;

            &::after {
              filter: brightness(0) invert(1);
            }
          }

          &::after {
            background-image: url(../../../assets/images/icons/arrow-down.svg);
            background-size: auto;
            width: 14px;
            height: 8px;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          .courses {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
