@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.topbar {
  background-color: $primary-color;
  position: relative;
  z-index: 50;

  @media only screen and (max-width: 767px) {
    width: 100%;
    z-index: unset;
  }

  .navbar-header {
    background-color: $navbar-bg;
    padding: 20px 0px 14px 60px;
    flex: 0 0 320px;
    max-width: 320px;
    position: relative;

    @media only screen and (max-width: 1400px) {
      flex: 0 0 260px;
      max-width: 260px;
    }

    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 7px 30px 6px 30px;
    }

    .navbar-brand {
      width: 100%;
      max-width: 61px;
      height: auto;
      display: block;

      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 67px;
        height: auto;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }
  }

  .navbar-collapse {
    padding: 20px 60px 14px 60px;
    flex: 0 0 calc(100% - 320px);
    max-width: calc(100% - 320px);

    @media only screen and (max-width: 1400px) {
      flex: 0 0 calc(100% - 260px);
      max-width: calc(100% - 260px);
    }

    @media only screen and (max-width: 767px) {
      padding: 14px 30px 16px 30px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      column-gap: 20px;
      flex-direction: row;

      .nav-item {
        color: $white-color;
        font-size: 26px;

        @media only screen and (max-width: 1199px) {
          font-size: 20px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        &.date_version {
          flex: 0 0 calc(100% - 46px);
          max-width: calc(100% - 46px);
        }

        &.scanner_block {
          flex: 0 0 26px;
          max-width: 26px;

          a {
            display: block;
            line-height: 1;
          }
        }
      }
    }
  }
}

.topbarRed {
  background-color: red;
  position: relative;
  z-index: 50;

  @media only screen and (max-width: 767px) {
    width: 100%;
    z-index: unset;
  }

  .navbar-header {
    background-color: $navbar-bg;
    padding: 20px 0px 14px 60px;
    flex: 0 0 320px;
    max-width: 320px;
    position: relative;

    @media only screen and (max-width: 1400px) {
      flex: 0 0 260px;
      max-width: 260px;
    }

    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 7px 30px 6px 30px;
    }

    .navbar-brand {
      width: 100%;
      max-width: 61px;
      height: auto;
      display: block;

      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 67px;
        height: auto;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }
  }

  .navbar-collapse {
    padding: 20px 60px 14px 60px;
    flex: 0 0 calc(100% - 320px);
    max-width: calc(100% - 320px);

    @media only screen and (max-width: 1400px) {
      flex: 0 0 calc(100% - 260px);
      max-width: calc(100% - 260px);
    }

    @media only screen and (max-width: 767px) {
      padding: 14px 30px 16px 30px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      column-gap: 20px;
      flex-direction: row;

      .nav-item {
        color: $white-color;
        font-size: 26px;

        @media only screen and (max-width: 1199px) {
          font-size: 20px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        &.date_version {
          flex: 0 0 calc(100% - 46px);
          max-width: calc(100% - 46px);
        }

        &.scanner_block {
          flex: 0 0 26px;
          max-width: 26px;

          a {
            display: block;
            line-height: 1;
          }
        }
      }
    }
  }
}

.topbarGreen {
  background-color: green;
  position: relative;
  z-index: 50;

  @media only screen and (max-width: 767px) {
    width: 100%;
    z-index: unset;
  }

  .navbar-header {
    background-color: $navbar-bg;
    padding: 20px 0px 14px 60px;
    flex: 0 0 320px;
    max-width: 320px;
    position: relative;

    @media only screen and (max-width: 1400px) {
      flex: 0 0 260px;
      max-width: 260px;
    }

    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 7px 30px 6px 30px;
    }

    .navbar-brand {
      width: 100%;
      max-width: 61px;
      height: auto;
      display: block;

      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 67px;
        height: auto;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }
  }

  .navbar-collapse {
    padding: 20px 60px 14px 60px;
    flex: 0 0 calc(100% - 320px);
    max-width: calc(100% - 320px);

    @media only screen and (max-width: 1400px) {
      flex: 0 0 calc(100% - 260px);
      max-width: calc(100% - 260px);
    }

    @media only screen and (max-width: 767px) {
      padding: 14px 30px 16px 30px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      column-gap: 20px;
      flex-direction: row;

      .nav-item {
        color: $white-color;
        font-size: 26px;

        @media only screen and (max-width: 1199px) {
          font-size: 20px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        &.date_version {
          flex: 0 0 calc(100% - 46px);
          max-width: calc(100% - 46px);
        }

        &.scanner_block {
          flex: 0 0 26px;
          max-width: 26px;

          a {
            display: block;
            line-height: 1;
          }
        }
      }
    }
  }
}

.topbarYellow {
  background-color: yellow;
  position: relative;
  z-index: 50;

  @media only screen and (max-width: 767px) {
    width: 100%;
    z-index: unset;
  }

  .navbar-header {
    background-color: $navbar-bg;
    padding: 20px 0px 14px 60px;
    flex: 0 0 320px;
    max-width: 320px;
    position: relative;

    @media only screen and (max-width: 1400px) {
      flex: 0 0 260px;
      max-width: 260px;
    }

    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 7px 30px 6px 30px;
    }

    .navbar-brand {
      width: 100%;
      max-width: 61px;
      height: auto;
      display: block;

      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 67px;
        height: auto;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }
  }

  .navbar-collapse {
    padding: 20px 60px 14px 60px;
    flex: 0 0 calc(100% - 320px);
    max-width: calc(100% - 320px);

    @media only screen and (max-width: 1400px) {
      flex: 0 0 calc(100% - 260px);
      max-width: calc(100% - 260px);
    }

    @media only screen and (max-width: 767px) {
      padding: 14px 30px 16px 30px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      column-gap: 20px;
      flex-direction: row;

      .nav-item {
        color: $white-color;
        font-size: 26px;

        @media only screen and (max-width: 1199px) {
          font-size: 20px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        &.date_version {
          flex: 0 0 calc(100% - 46px);
          max-width: calc(100% - 46px);
        }

        &.scanner_block {
          flex: 0 0 26px;
          max-width: 26px;

          a {
            display: block;
            line-height: 1;
          }
        }
      }
    }
  }
}
