@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.my_package_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;

    .user_pic_name {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -6px;
      padding: 20px 0px;
      border-bottom: 1px solid #eeeeee;

      @media only screen and (max-width: 767px) {
        border-bottom: 0px;
        position: absolute;
        padding: 0px;
        margin: 0px;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      .user_pic {
        flex: 0 0 81px;
        max-width: 81px;
        padding: 0 6px;

        @media only screen and (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
        }

        img {
          width: 69px;
          height: 68px;
          border-radius: 50%;
          object-fit: cover;

          @media only screen and (max-width: 767px) {
            width: 61px;
            height: 61px;
          }
        }
      }

      .user_name {
        flex: 0 0 calc(100% - 81px);
        max-width: calc(100% - 81px);
        padding: 0 6px;

        @media only screen and (max-width: 767px) {
          display: none;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 25px;
          color: $white-color;

          span {
            display: block;
          }
        }
      }
    }

    .my_package_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 10px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;
        color: $white-color;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .my_package {
    .my_package_inner {
      .my_package_card_status {
        display: block;
        margin-bottom: 40px;

        @media only screen and (max-width: 767px) {
          margin-bottom: 30px;
        }

        @media only screen and (max-width: 575px) {
          margin-bottom: 20px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: 575px) {
            row-gap: 10px;
          }

          li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            text-align: center;

            @media only screen and (max-width: 575px) {
              flex: 0 0 50%;
              max-width: 50%;
            }

            a {
              font-size: 16px;
              color: $white-color;
              border-bottom: 1px solid #e4e4e4;
              border-radius: 0px;
              padding: 5px 20px;
              text-decoration: none;

              &.active {
                background-color: transparent;
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
              }
            }
          }
        }
      }

      .tab-pane {
        &.fade {
          display: none;
        }

        &.fade.active {
          display: block;
        }

        .my_package_cards {
          margin: 0 -18px;

          .my_package_card {
            margin-bottom: 36px;
            padding: 0 18px;

            .my_package_card_inner {
              box-shadow: $drop-shadow;
              border-radius: 20px;
              padding: 20px 28px;
              height: 100%;
              background-color: $dark-bg;

              .my_package_card_title {
                padding-bottom: 23px;
                margin-bottom: 14px;
                border-bottom: 1px solid #e4e4e4;

                h2 {
                  text-transform: uppercase;
                  font-weight: 500;
                  color: $white-color;
                }
              }

              .my_package_detail {
                .my_package_list {
                  .my_package_item {
                    margin-bottom: 19px;
                    font-size: 16px;
                    font-weight: normal;
                    color: $white-color;

                    span {
                      text-transform: uppercase;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
