@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.pause_cancel_section {
  padding: 0px 0px 50px;

  .dataToHide {
    display: none;
  }

  .user_detail {
    position: relative;

    .pause_cancel_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .pause_cancel_inner {
    .pause_cancel_table {
      .table-group-divider {
        border-top: 2px solid $primary-color;
      }
      .bg_primary {
        background-color: $primary-color;
        color: $white-color;
        border: 1px solid $primary-color;
        padding: 1rem;
        font-weight: bold;

        &::first-letter {
          text-transform: uppercase;
        }
      }
      .table {
        margin-bottom: 50px;

        @media only screen and (max-width: 1199px) {
          margin-bottom: 30px;
        }

        .table-group-divider {
          border-top: 2px solid $primary-color;
        }

        th {
          white-space: nowrap;

          &.bg_primary {
            background-color: $primary-color;
            color: $white-color;
            border: 1px solid $primary-color;
          }
        }

        tbody {
          tr {
            td {
              &:first-child {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}
