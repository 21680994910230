@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.video_list_section {
  padding: 0px 0px 50px;

  .user_detail {
    .video_list_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .video_list {
    .video_list_inner {
      .search_filter_block {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -10px 40px;

        @media only screen and (max-width: 1199px) {
          margin: 0 -5px 40px;
        }

        .search_block {
          flex: 0 0 calc(100% - 75px);
          max-width: calc(100% - 75px);
          padding: 0 10px;
          position: relative;

          @media only screen and (max-width: 1199px) {
            padding: 0 5px;
          }

          form {
            .input-group {
              .form-control {
                border-radius: 10px 0px 0px 10px;
                background-color: $black-color;
                color: $white-color;

                &:focus {
                  outline: none;
                  box-shadow: none;
                  border-color: #909090;
                }
              }
            }

            .input-group-btn {
              border: 1px solid #ced4da;
              border-radius: 0 10px 10px 0px;

              .btn {
                padding: 12px 15px;

                img {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          .video_search_list {
            position: relative;
            left: 0;
            right: 0;
            width: 100%;

            ul {
              border: 1px solid #ced4da;
              background-color: $dark-bg;
              border-top: 0px;
              border-radius: 0px 0px 10px 10px;
              box-shadow: $drop-shadow;
              max-height: 300px;
              overflow-y: scroll;
              margin-top: -10px;
              z-index: 99;
              position: relative;

              &::-webkit-scrollbar {
                display: none;
              }

              li {
                font-size: 16px;
                padding: 10px;

                &:hover {
                  background-color: $primary-color;

                  a {
                    color: $white-color;
                  }
                }
              }
            }
          }
        }

        .filter_block {
          flex: 0 0 75px;
          max-width: 75px;
          padding: 0 10px;

          @media only screen and (max-width: 1199px) {
            padding: 0 5px;
          }

          a {
            width: 54.5px;
            height: 54.5px;
            padding: 10px;
            border: 1px solid #ced4da;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            img {
              object-fit: contain;
              width: 100%;
            }
          }
        }
      }

      .video_list_cards {
        flex: 0 0 100%;
        max-width: 100%;
        .video_list_card {
          margin-bottom: 36px;

          .video_list_card_inner {
            box-shadow: $drop-shadow;
            background-color: $dark-bg;
            border-radius: 20px;
            padding: 20px 28px;
            height: 100%;

            .video_list_card_row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              margin: 0 -15px;

              @media only screen and (max-width: 991px) {
                margin: 0 -12px;
              }

              @media only screen and (max-width: 575px) {
                justify-content: center;
                margin: 0;
              }

              .video_list_card_column_1 {
                flex: 0 0 calc(100% - 80px);
                max-width: calc(100% - 80px);
                padding: 0 15px;

                @media only screen and (max-width: 991px) {
                  flex: 0 0 calc(100% - 60px);
                  max-width: calc(100% - 60px);
                  padding: 0 12px;
                }

                @media only screen and (max-width: 575px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                  padding: 0;
                }

                .video_list_card_title {
                  padding: 0 0 14px 0;
                  margin-bottom: 14px;
                  border-bottom: 1px solid #e4e4e4;

                  h3 {
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $white-color;

                    @media only screen and (max-width: 575px) {
                      text-align: center;
                    }
                  }
                }

                .video_list_detail {
                  position: relative;
                  word-break: break-all;
                  color: $white-color;

                  @media only screen and (max-width: 575px) {
                    text-align: center;
                  }

                  p,
                  a {
                    word-break: break-all;
                    color: $white-color;

                    @media only screen and (max-width: 575px) {
                      text-align: center;
                    }
                  }
                }
              }

              .video_list_card_column_2 {
                flex: 0 0 80px;
                max-width: 80px;
                padding: 0 15px;

                @media only screen and (max-width: 991px) {
                  flex: 0 0 60px;
                  max-width: 60px;
                  padding: 0 12px;
                }

                @media only screen and (max-width: 575px) {
                  flex: 0 0 50px;
                  max-width: 50px;
                  padding: 0;
                }

                .delete-icon {
                  width: 100%;

                  @media only screen and (max-width: 575px) {
                    margin-top: 25px;
                  }

                  img {
                    width: 100%;
                    max-width: 50px;
                    object-fit: contain;
                    background-color: $white-color;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .video_list_modal {
    .modal {
      .modal-dialog {
        max-width: 65%;

        @media only screen and (max-width: 1199px) {
          max-width: 75%;
        }

        @media only screen and (max-width: 767px) {
          max-width: 95%;
        }

        .modal-content {
          background-color: $dark-bg;

          .modal-header {
            a {
              width: 24px;
              height: 24px;

              &.btn-close {
                opacity: 1;
                background: transparent;
                background-image: none;
              }
            }
          }

          .modal-body {
            .date_time_title {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              column-gap: 30px;
              margin-bottom: 25px;

              @media only screen and (max-width: 767px) {
                justify-content: center;
              }

              .date_time {
                position: relative;

                @media only screen and (max-width: 767px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  column-gap: 20px;
                  margin-bottom: 10px;
                }

                &::after {
                  position: absolute;
                  top: 0;
                  right: -15px;
                  width: 1px;
                  height: 100%;
                  content: "";
                  display: block;
                  background-color: $border-color;

                  @media only screen and (max-width: 767px) {
                    width: 50%;
                    height: 1px;
                    top: auto;
                    bottom: -5px;
                    right: 0px;
                    transform: translate(-50%, 0px);
                  }
                }

                .date {
                  p {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;

                    @media only screen and (max-width: 767px) {
                      margin-bottom: 0px;
                    }
                  }
                }

                .time {
                  p {
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }

              .title_detail {
                color: $white-color;

                @media only screen and (max-width: 767px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                  text-align: center;
                }

                h3 {
                  font-weight: 500;
                  margin-bottom: 5px;
                }
              }
            }

            .video_block {
              height: 45vh;
            }
          }
        }

      }
    }
  }

  .filter_modal {
    .modal {
      .modal-dialog {
        max-width: 65%;

        @media only screen and (max-width: 991px) {
          max-width: 95%;
        }

        .modal-content {
          background-color: $dark-bg;

          .modal-header {
            a {
              width: 24px;
              height: 24px;

              &.btn-close {
                opacity: 1;
                background: transparent;
                background-image: none;
              }
            }
          }

          .modal-body {
            form {
              margin-top: 40px;

              @media only screen and (max-width: 991px) {
                margin-top: 20px;
              }

              .category-select {
                position: relative;
                display: inline-block;
                display: block;
                width: 100%;
                margin: 0 auto 20px;

                @media only screen and (max-width: 767px) {
                  margin: 0 auto 10px;
                }

                .dropdown-toggle3 {
                  border: 1px solid $border-color;
                  width: 100%;
                  padding: 18px 25px;
                  background-color: $black-color;
                  border-radius: 50px;
                  color: $white-color;
                  font-size: 14px;
                  line-height: 1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  &.show {
                    border-radius: 20px 20px 0px 0px;

                    &::after {
                      transform: rotate(180deg);
                    }
                  }

                  &::after {
                    border: 0px;
                    margin: 0px;
                    background-image: url(../../../assets/images/icons/arrow-down.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                    width: 16px;
                    height: 10px;
                    vertical-align: unset;
                    transition: 0.5s all ease-in-out;
                  }

                  &:active {
                    border-color: $border-color;
                      background-color: $dark-bg;
                      color: $white-color;
                  }
                }

                .dropdown-menu {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  right: 0;
                  z-index: 1000;
                  display: none;
                  padding: 0 0 25px 0;
                  max-height: 150px;
                  overflow-y: auto;

                  &::-webkit-scrollbar {
                    display: none;
                  }

                  &.show {
                    display: block;
                    border-radius: 0 0 20px 20px;
                    border-color: $border-color;
                  }

                  .dropdown-item {
                    color: $white-color;
                    background-color: $black-color;
                    font-size: 14px;
                    line-height: 1;
                    padding: 14px 25px;

                    &:hover {
                      background-color: $primary-color;
                      color: $white-color;
                    }
                  }
                }
              }

              .dance-select {
                position: relative;
                display: inline-block;
                display: block;
                width: 100%;
                margin: 0 auto 20px;

                @media only screen and (max-width: 767px) {
                  margin: 0 auto 10px;
                }

                .dropdown-toggle2 {
                  border: 1px solid $border-color;
                  width: 100%;
                  padding: 18px 25px;
                  background-color: $black-color;
                    border-radius: 50px;
                    color: $white-color;
                  font-size: 14px;
                  line-height: 1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  &.show {
                    border-radius: 20px 20px 0px 0px;

                    &::after {
                      transform: rotate(180deg);
                    }
                  }

                  &::after {
                    border: 0px;
                    margin: 0px;
                    background-image: url(../../../assets/images/icons/arrow-down.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                    width: 16px;
                    height: 10px;
                    vertical-align: unset;
                    transition: 0.5s all ease-in-out;
                  }

                  &:active {
                    border-color: $border-color;
                      background-color: $dark-bg;
                      color: $white-color;
                  }
                }

                .dropdown-menu {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  right: 0;
                  z-index: 1000;
                  display: none;
                  padding: 0 0 25px 0;
                  max-height: 150px;
                  overflow-y: auto;

                  &::-webkit-scrollbar {
                    display: none;
                  }

                  &.show {
                    display: block;
                    border-radius: 0 0 20px 20px;
                    border-color: $border-color;
                  }

                  .dropdown-item {
                    color: $white-color;
                    background-color: $black-color;
                    font-size: 14px;
                    line-height: 1;
                    padding: 14px 25px;

                    &:hover {
                      background-color: $primary-color;
                      color: $white-color;
                    }
                  }
                }
              }

              .dance-category {
                position: relative;
                display: inline-block;
                display: block;
                width: 100%;
                margin: 0 auto 20px;

                @media only screen and (max-width: 767px) {
                  margin: 0 auto 10px;
                }

                .dropdown-toggle1 {
                  border: 1px solid $border-color;
                  width: 100%;
                  padding: 18px 25px;
                  background-color: $black-color;
                    border-radius: 50px;
                    color: $white-color;
                  font-size: 14px;
                  line-height: 1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  &.show {
                    border-radius: 20px 20px 0px 0px;

                    &::after {
                      transform: rotate(180deg);
                    }
                  }

                  &::after {
                    border: 0px;
                    margin: 0px;
                    background-image: url(../../../assets/images/icons/arrow-down.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                    width: 16px;
                    height: 10px;
                    vertical-align: unset;
                    transition: 0.5s all ease-in-out;
                  }

                  &:active {
                    border-color: $border-color;
                    background-color: $dark-bg;
                    color: $white-color;
                  }
                }

                .dropdown-menu {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  right: 0;
                  z-index: 1000;
                  display: none;
                  padding: 0 0 25px 0;
                  max-height: 150px;
                  overflow-y: auto;

                  &::-webkit-scrollbar {
                    display: none;
                  }

                  &.show {
                    display: block;
                    border-radius: 0 0 20px 20px;
                    border-color: $border-color;
                  }

                  .dropdown-item {
                    color: $white-color;
                    background-color: $black-color;
                    font-size: 14px;
                    line-height: 1;
                    padding: 14px 25px;

                    &:hover {
                      background-color: $primary-color;
                      color: $white-color;
                    }
                  }
                }
              }

              .some-title {
                .form-control {
                  border: 1px solid $border-color;
                  width: 100%;
                  padding: 18px 25px;
                  background-color: $black-color;
                    border-radius: 50px;
                    color: $white-color;
                  font-size: 14px;
                  line-height: 1;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 52px;

                  &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: $border-color;
                    background-color: $black-color;
                  }

                  &::placeholder {
                    color: $white-color;
                  }
                }
              }
            }
          }

          .modal-footer {
            border-top: 0px;
          }
        }


      }
    }
  }
}

.player_size_manager {
  display: none !important;
}

.player_button_container {
  display: none !important;
}

.clip-stats__breadcrumb {
  margin: 0 !important;
}