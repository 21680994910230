@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.checklist_report_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;

    .checklist_report_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .checklist_report_inner {
    .checklist_report_table {
      .table {
        max-width: 500px;

        th {
          white-space: nowrap;
          color: $white-color;
          background-color: $dark-bg;

          &:last-child {
            text-align: center;
          }
        }

        tbody {
          tr {
            td {
              vertical-align: middle;
              color: $white-color;
              background-color: $dark-bg;

              &:last-child {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
