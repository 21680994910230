@import "./variables.scss";

// Typography
@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816-Light.eot");
  src: url("../fonts/Brother1816-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Brother1816-Light.woff2") format("woff2"),
    url("../fonts/Brother1816-Light.woff") format("woff"),
    url("../fonts/Brother1816-Light.ttf") format("truetype"),
    url("../fonts/Brother1816-Light.svg#Brother1816-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816-Book.eot");
  src: url("../fonts/Brother1816-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Brother1816-Book.woff2") format("woff2"),
    url("../fonts/Brother1816-Book.woff") format("woff"),
    url("../fonts/Brother1816-Book.ttf") format("truetype"),
    url("../fonts/Brother1816-Book.svg#Brother1816-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816-Medium.eot");
  src: url("../fonts/Brother1816-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Brother1816-Medium.woff2") format("woff2"),
    url("../fonts/Brother1816-Medium.woff") format("woff"),
    url("../fonts/Brother1816-Medium.ttf") format("truetype"),
    url("../fonts/Brother1816-Medium.svg#Brother1816-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816-Bold.eot");
  src: url("../fonts/Brother1816-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Brother1816-Bold.woff2") format("woff2"),
    url("../fonts/Brother1816-Bold.woff") format("woff"),
    url("../fonts/Brother1816-Bold.ttf") format("truetype"),
    url("../fonts/Brother1816-Bold.svg#Brother1816-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816-ExtraBold.eot");
  src: url("../fonts/Brother1816-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Brother1816-ExtraBold.woff2") format("woff2"),
    url("../fonts/Brother1816-ExtraBold.woff") format("woff"),
    url("../fonts/Brother1816-ExtraBold.ttf") format("truetype"),
    url("../fonts/Brother1816-ExtraBold.svg#Brother1816-ExtraBold")
      format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Brother 1816";
  src: url("../fonts/Brother1816-Black.eot");
  src: url("../fonts/Brother1816-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Brother1816-Black.woff2") format("woff2"),
    url("../fonts/Brother1816-Black.woff") format("woff"),
    url("../fonts/Brother1816-Black.ttf") format("truetype"),
    url("../fonts/Brother1816-Black.svg#Brother1816-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

body .container,
body .container-fluid,
body .container-xxl,
body .container-xl,
body .container-lg,
body .container-md,
body .container-sm {
  padding-right: 30px;
  padding-left: 30px;

  @media only screen and (min-width: 1600px) {
    padding-right: 60px;
    padding-left: 60px;
  }
}

body {
  color: $default-color;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  background-color: $black-color;
}

@mixin heading {
  font-family: "Brother 1816";
  margin-bottom: 0px;
  letter-spacing: 0px;
  line-height: 1.2;
  color: $white-color;
  font-weight: 700;
}

h1 {
  @include heading();
  font-size: 42px;

  @media only screen and (max-width: 1199px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
  }
}

h2 {
  @include heading();
  font-size: 24px;
  color: $white-color;

  @media only screen and (max-width: 1199px) {
    font-size: 20px;
  }
}

h3 {
  @include heading();
  font-size: 18px;
}

p {
  font-family: "Brother 1816";
  margin-bottom: 0px;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 1.2;
  // color: $default-color;
  color: $white-color;
  font-weight: normal;
}

a {
  font-family: "Brother 1816";
  margin-bottom: 0px;
  letter-spacing: 0px;
  // color: $default-color;
  color: $white-color;
  font-size: 16px;
  line-height: 1.2;
  transition: 0.5s all ease-in-out;
  text-decoration: none;
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

span {
  font-family: "Brother 1816";
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: $border-color;
}

.table > :not(caption) > * > * {
  padding: 1rem;
}

@mixin btn-style {
  padding: 16px 48px;
  font-size: 14px;
  line-height: 1;
  border-radius: 50px;
  transition: 0.5s all ease-in-out;
}

.btn_primary {
  @include btn-style();
  color: $white-color;
  background-color: $primary-color;
  border: 2px solid $primary-color;

  &:hover {
    color: $white-color;
    background-color: $btn-primary-hover;
    border: 2px solid $btn-primary-hover;
  }
}

.btn_secondary {
  @include btn-style();
  color: $white-color;
  background-color: $secondary-color;
  border: 2px solid $secondary-color;

  &:hover {
    color: $white-color;
    background-color: $btn-secondary-hover;
    border: 2px solid $btn-secondary-hover;
  }
}

::placeholder {
  color: $white-color !important;
}
