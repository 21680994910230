@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.openbill_section {
    padding: 0px 0px 50px;

    .user_detail {

        .notifications_title {
            padding: 41px 0px 24px 0px;

            @media only screen and (max-width: 767px) {
                padding: 36px 0px;
                border-bottom: 1px solid #E4E4E4;
                margin-bottom: 30px;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 500;
                color: $white-color;

                @media only screen and (max-width: 1199px) {
                    font-weight: 700;
                }
            }
        }
    }

    .openbill_inner {
        .openbill_table {
            .table {
                .table-group-divider {
                    border-top: 2px solid $primary-color;

                    @media only screen and (max-width: 1199px) {
                        padding: 0;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: stretch;
                        gap: 20px;
                        border-top: none;
                    }

                    tr {
                        @media only screen and (max-width: 1199px) {
                            flex: 0 0 calc(50% - 10px);
                            max-width: calc(50% - 10px);
                        }

                        @media only screen and (max-width: 991px) {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }

                th {
                    white-space: nowrap;
                    background-color: $dark-bg;
                    color: $white-color;
                }
                td{
                    background-color: $dark-bg;
                    color: $white-color;
                }
            }

            .if_no_records {
                p {
                    color: $primary-color;
                }
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        table thead {
            display: none;
        }

        table tbody {
            display: flex;
            flex-wrap: wrap;
            padding: 4px;
        }

        table tbody tr,
        table tbody td {
            display: block;
            border: 0;
        }

        table tbody td:before {
            width: auto;
            display: inline-block;
            text-transform: capitalize;
            font-weight: normal;
            margin-right: 10px;
            font-weight: 500;
        }

        table tbody td {
            &:nth-child(1) {
                &::before {
                    content: "Name :";
                }
            }

            &:nth-child(2) {
                &::before {
                    content: "Category :";
                }
            }

            &:nth-child(3) {
                &::before {
                    content: "Text :";
                }
            }

            &:nth-child(4) {
                &::before {
                    content: "Ticket Received :";
                }
            }

            &:nth-child(5) {
                &::before {
                    content: "Price :";
                }
            }

            &:nth-child(6) {
                &::before {
                    content: "Status :";
                }
            }

            &:nth-child(7) {
                &::before {
                    content: "Created Date :";
                }
            }
        }

        table tbody td.status {
            position: absolute;
            top: 4px;
            right: 4px;
        }

        table tbody tr {
            position: relative;
            border: 1px solid #E7E7E7;
            border-width: 1px;
            padding: 16px;
        }

        td:empty {
            display: none;
        }
    }
}