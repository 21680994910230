@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.chat_section {
    padding: 25px 0px 110px;

    .chat_title {
        padding: 0px 0px 20px;
        border-bottom: 1px solid rgb(214, 214, 214);
        margin-bottom: 40px;
    }

    .chat_inner {
        .msg {
            display: flex;
            margin-bottom: 15px;

            &.left-msg {
                align-items: flex-start;
                justify-content: flex-start;

                .msg-bubble {
                    border-radius: 0px 15px 15px 15px;
                    background: #f9e0bb;
                }
            }

            &.right-msg {
                align-items: flex-start;
                justify-content: flex-end;

                .msg-bubble {
                    border-radius: 15px 0px 15px 15px;
                    background: #ffc8d7;
                }
            }

            .msg-bubble {
                max-width: 450px;
                padding: 15px;

                @media only screen and (max-width: 1199px) {
                    max-width: 65%;
                }

                @media only screen and (max-width: 767px) {
                    max-width: 75%;
                }

                .msg-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 30px;
                    margin-bottom: 5px;

                    @media only screen and (max-width: 767px) {
                        margin-bottom: 3px;
                    }

                    .msg-info-name {
                        text-transform: capitalize;
                        font-size: 16px;
                        font-weight: 600;
                        font-family: 'Brother 1816';

                        @media only screen and (max-width: 767px) {
                            font-size: 14px;
                        }
                    }

                    .msg-info-time {
                        font-size: 10px;
                        font-weight: 600;
                        font-family: 'Brother 1816';
                        opacity: 0.5;
                    }
                }

                .msg-text {
                    @media only screen and (max-width: 767px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.chat_form {
    padding: 10px 0px 20px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 320px);
    background: $black-color;

    @media only screen and (max-width: 1400px) {
        width: calc(100% - 260px);
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .chat_form_inner {
        padding-top: 20px;
        border-top: 1px solid $primary-color;

        .form {
            position: relative;

            .form-control {
                font-family: "Brother 1816";
                border: 1px solid #eeeeee;
                border-radius: 10px;
                height: 50px;
                font-size: 16px;
                line-height: 23px;
                color: $white-color;
                background-color: $dark-bg;
                padding: 6px 90px 6px 12px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .attachment_btn_part {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;
                position: absolute;
                top: 0;
                right: 6px;
                bottom: 0;

                .input-group {
                    max-width: 22px;

                    .input-group-text {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        cursor: pointer;

                        img {
                            width: 22px;
                            height: auto;
                            object-fit: contain;
                        }
                    }
                }

                button {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    height: 38px;
                    padding: 5px 10px;
                    transition: 0.5s all ease-in-out;

                    &:hover {
                        background-color: $btn-primary-hover;
                        border-color: $btn-primary-hover;
                    }

                    img {
                        width: 16px;
                        height: 14px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

::placeholder{
    color: $white-color !important;
}