@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.course_management_blocks {
  .course_management_blocks_inner {
    padding: 10px 0px;

    .course_management_blocks_group {
      padding: 50px 0px;

      @media only screen and (max-width: 767px) {
        padding: 40px 0px;
      }

      .row {
        margin: 0 -19px;
        row-gap: 38px;

        @media only screen and (max-width: 767px) {
          margin: 0 -6px;
          row-gap: 12px;
        }

        .course_management_block {
          padding: 0 19px;

          @media only screen and (max-width: 767px) {
            padding: 0 6px;
          }

          @media only screen and (max-width: 374px) {
            flex: 0 0 100%;
            max-width: 100%;
          }

          .course_management_block_inner {
            // background-color: $light-gray-bg;
            background-color: $dark-bg;
            display: block;
            height: 100%;
            border-radius: 20px;
            text-align: center;
            padding: 40px 18px 40px;

            @media only screen and (max-width: 767px) {
              padding: 30px 6px 30px;
            }

            &:hover {
              transition: 0.5s all ease-in-out;
              background-color: $primary-color;

              .course_management_block_icon {
                img {
                  filter: brightness(0) invert(1);
                  transition: 0.5s all ease-in-out;
                }
              }

              h2 {
                color: $white-color;
                transition: 0.5s all ease-in-out;
              }
            }

            .course_management_block_icon {
              margin: 0 auto;
              width: 49px;
              height: 49px;

              @media only screen and (max-width: 767px) {
                width: 25px;
                height: 25px;
              }

              img {
                object-fit: contain;
                filter: brightness(0) invert(1);

                @media only screen and (max-width: 767px) {
                  width: 100%;
                  height: 25px;
                }
              }
            }

            h2 {
              margin-top: 26px;
              font-size: 24px;
              line-height: 34px;
              font-weight: 500;
              text-transform: uppercase;
              word-wrap: break-word;

              @media only screen and (max-width: 1800px) {
                font-size: 22px;
                line-height: 30px;
              }

              @media only screen and (max-width: 1199px) {
                font-size: 18px;
                line-height: 22px;
              }

              @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
