@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.chacked_section {
  padding: 0px 0px 50px;

  .user_detail {
    .notifications_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .chacked_inner {
    .chacked_para {
      margin-bottom: 50px;

      P {
        margin-bottom: 5px;
      }
    }

    .chacked_img {
      margin-bottom: 50px;

      img {
        width: 100%;
        max-width: 670px;
        object-fit: cover;
        box-shadow: $drop-shadow;
      }
    }

    .open_payment,
    .open_bill {
      margin-bottom: 50px;

      h3 {
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    .chacked_table {
      .table_heading {
        h3 {
          text-transform: uppercase;
          font-weight: 500;
          margin-bottom: 20px;
        }
      }

      .table {
        .table-group-divider {
          border-top: 2px solid $primary-color;

          @media only screen and (max-width: 1199px) {
              padding: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: stretch;
              gap: 20px;
              border-top: none;
            }
          
            tr {
              @media only screen and (max-width: 1199px) {
                flex: 0 0 calc(50% - 10px);
                max-width: calc(50% - 10px);
              }
          
              @media only screen and (max-width: 991px) {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
        }

        th {
          white-space: nowrap;
        }
      }

      .if_no_records {
        p {
          color: $primary-color;
        }
      }

            @media only screen and (max-width: 1199px) {
              table thead {
                display: none;
              }
      
              table tbody {
                display: flex;
                flex-wrap: wrap;
                padding: 4px;
              }
      
              table tbody tr,
              table tbody td {
                display: block;
                border: 0;
              }
      
              table tbody td:before {
                width: auto;
                display: inline-block;
                text-transform: capitalize;
                font-weight: normal;
                margin-right: 10px;
                font-weight: 500;
              }
      
              table tbody td {
                &:nth-child(1) {
                  &::before {
                    content: "Course Title :";
                  }
                }
      
                &:nth-child(2) {
                  &::before {
                    content: "Activated Date :";
                  }
                }
      
                &:nth-child(3) {
                  &::before {
                    content: "Expired Date :";
                  }
                }
      
                &:nth-child(4) {
                  &::before {
                    content: "Status :";
                  }
                }
              }
      
              table tbody td.status {
                position: absolute;
                top: 4px;
                right: 4px;
              }
      
              table tbody tr {
                position: relative;
                border: 1px solid #E7E7E7;
                border-width: 1px;
                padding: 16px;
              }
      
              td:empty {
                display: none;
              }
            }
    }
  }
}
