@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.music_playlist_section {
  padding: 0px 0px 50px;

  .dataToHide {
    display: none;
  }

  .user_detail {
    .music_playlist_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .music_playlist {
    .music_playlist_inner {
      .music_playlist_cards {
        .music_playlist_card {
          margin-bottom: 24px;

          .music_playlist_card_inner {
            box-shadow: $drop-shadow;
            background-color: $dark-bg;
            border-radius: 20px;
            padding: 20px 28px;
            height: 100%;

            .music_playlist_card_row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              margin: 0 -15px;

              @media only screen and (max-width: 991px) {
                margin: 0 -12px;
              }

              @media only screen and (max-width: 575px) {
                justify-content: center;
                margin: 0;
              }

              .music_playlist_card_column {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0px;

                .music_playlist_card_title {
                  padding: 0 0 14px 0;
                  margin-bottom: 14px;
                  border-bottom: 1px solid #e4e4e4;
                  text-align: center;

                  h3 {
                    text-transform: uppercase;
                    font-weight: 500;

                    @media only screen and (max-width: 575px) {
                      text-align: center;
                    }
                  }
                }

                .delete-icon {
                  width: 100%;
                  text-align: center;

                  img {
                    width: 100%;
                    max-width: 50px;
                    object-fit: contain;
                    border-radius: 50%;
                    background-color: $white-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-content {
  background-color: $dark-bg;

  .modal-header {
    a {
      width: 24px;
      height: 24px;

      &.btn-close {
        opacity: 1;
        background: transparent;
        background-image: none;
      }
    }
  }
}
