@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.left_sidebar {
  position: absolute;
  width: 320px;
  top: 0px;
  z-index: 20;
  padding-top: 91px;
  background: $navbar-bg;
  min-height: 100vh;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;

  @media only screen and (max-width: 1400px) {
    width: 260px;
  }

  @media only screen and (max-width: 767px) {
    width: 320px;
    left: -320px;
    padding-top: 76px;
  }

  &.visible {
    @media only screen and (max-width: 767px) {
      left: 0px;
      padding-top: 76px;
    }
  }

  .scroll-sidebar {
    position: relative;
    min-height: calc(100vh - 91px);

    .sidebar_nav {
      ul {
        list-style-type: none;
        padding-left: 0;

        &#sidebarnav {
          height: calc(100vh - 200px);
          overflow: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .sidebar_nav_item {
          border-bottom: 1px solid $nabar-border-color;
          background: linear-gradient(
            to right,
            $primary-color 50%,
            transparent 50%
          );
          background-size: 200% 100%;
          background-position: right bottom;
          transition: all 0.5s ease-out;

          &.active {
            background-color: $primary-color;
            border-bottom: 1px solid $primary-color;
          }

          &:hover {
            background-position: left bottom;
          }

          .sidebar_nav_link {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 11px;
            color: $white-color;
            font-size: 16px;
            line-height: 1;
            padding: 18px 60px;
            text-transform: uppercase;
            text-decoration: none;

            .sidebar_nav_link_icon {
              width: 100%;
              max-width: 21px;

              img {
                filter: brightness(0) invert(1);
                transition: 0.5s all ease-in-out;
              }
            }
          }
        }
      }

      .logout_btn {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;

        .logout_link {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 11px;
          color: $white-color;
          font-size: 16px;
          line-height: 1;
          padding: 18px 60px;
          border-top: 1px solid $nabar-border-color;
          text-transform: uppercase;
          text-decoration: none;

          &:hover {
            background-color: $primary-color;
            border-top: 1px solid $primary-color;
            transition: 0.5s all ease-in-out;
          }

          .logout_icon {
            width: 100%;
            max-width: 21px;
          }
        }
      }
    }
  }
}

.sidebar_show {
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $white-color;
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.92;
  }
}

#sidebar_btn {
  display: inline-block;
  border: none;
  background: transparent !important;
  position: absolute;
  top: 50%;
  left: 276px;
  transform: translate(0, -50%);
  z-index: 999;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;

  @media only screen and (min-width: 768px) {
    display: none;
  }

  &.collapsed {
    left: 30px;

    .icon-bar {
      &:nth-of-type(1) {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
      }

      &:nth-of-type(2) {
        opacity: 1;
        filter: alpha(opacity=100);
      }

      &:nth-of-type(3) {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
      }
    }
  }

  &:hover {
    background: transparent !important;
  }

  .icon-bar {
    width: 28px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: $white-color;
    display: block;
    width: 28px;
    height: 2px;
    border-radius: 10px;

    &:nth-of-type(1) {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transform-origin: 0% 10%;
      -ms-transform-origin: 0% 10%;
      transform-origin: 0% 10%;
    }

    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity=0);
    }

    &:nth-of-type(3) {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 0% 90%;
      -ms-transform-origin: 0% 90%;
      transform-origin: 0% 90%;
    }
  }

  .icon-bar + .icon-bar {
    margin-top: 8px;
  }
}
