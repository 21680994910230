@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.ceo_checklist_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;

    .ceo_checklist_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .ceo_checklist_inner {
    .ceo_checklist_table {
      .table {
        .table-group-divider {
          border-top: 2px solid $primary-color;
        }

        th {
          white-space: nowrap;
          background-color: $dark-bg;
          color: $white-color;
        }

        tbody {
          tr {
            td {
              vertical-align: middle;
              background-color: $dark-bg;
              color: $white-color;
              white-space: nowrap;

              // &:last-child {
              //   display: flex;
              //   gap: 10px;
              // }

              .ok_btn {
                font-size: 14px;
                padding: 10px 20px;

                line-height: 1;
                border-radius: 50px;
                transition: 0.5s all ease-in-out;
                color: $white-color;
                background-color: $primary-color;
                border: 2px solid $primary-color;

                &:hover {
                  color: $white-color;
                  background-color: $btn-primary-hover;
                  border: 2px solid $btn-primary-hover;
                }
              }

              .notok_btn {
                font-size: 14px;
                padding: 10px 20px;
                line-height: 1;
                border-radius: 50px;
                margin-top: 5px;
                transition: 0.5s all ease-in-out;
                color: $white-color;
                background-color: $secondary-color;
                border: 2px solid $secondary-color;

                &:hover {
                  color: $white-color;
                  background-color: $btn-secondary-hover;
                  border: 2px solid $btn-secondary-hover;
                }
              }

              select {
                height: 40px;
                border: 1px solid #eeeeee;
                padding: 5px 10px;
                border-radius: 10px;
                color: $white-color;
                background-color: $black-color;
                font-size: 14px;

                &:focus {
                  border: 1px solid #eeeeee;
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
