@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.abschluss_section {
  padding: 0px 0px 50px;
  .dataToHide {
    display: none;
  }
  .user_detail {
    position: relative;
    border-bottom: 1px solid $border-color;

    .abschluss_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .abschluss {
    margin-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }

    .abschluss_inner {
      .abschluss_cards {
        margin: 0 -18px 0px;
        padding-bottom: 0px;

        .abschluss_card {
          margin-bottom: 36px;
          padding: 0 18px;

          @media only screen and (max-width: 575px) {
            margin-bottom: 25px;
          }

          .abschluss_card_inner {
            box-shadow: $drop-shadow;
            background-color: $dark-bg;
            border-radius: 20px;
            padding: 20px 28px;
            height: 100%;

            @media only screen and (max-width: 575px) {
              box-shadow: none;
              border-radius: 0px;
              padding: 10px 0px;
            }

            .abschluss_card_title {
              padding: 0 0 23px 0;
              margin-bottom: 14px;
              border-bottom: 1px solid #e4e4e4;
              display: flex;
              justify-content: space-between;

              @media only screen and (max-width: 575px) {
                padding: 0 0 15px 0;
                margin-bottom: 10px;
              }

              h2 {
                flex: 0 0 calc(100% - 40px);
                max-width: calc(100% - 40px);
                text-transform: uppercase;
                font-weight: 500;
              }

              .form-check-input {
                flex: 0 0 20px;
                max-width: 20px;
                width: 100%;
                height: 20px;
                margin: 2px 0px 0 0px;
                border-radius: 0px;

                &:checked {
                  background-color: $primary-color;
                  border-color: $primary-color;
                  border-radius: 0px;
                }

                &:focus {
                  border-color: rgba(0, 0, 0, 0.25);
                  box-shadow: none;
                  outline: none;
                }
              }
            }

            .abschluss_detail {
              display: flex;
              flex-wrap: wrap;

              .form-check {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 18px;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;

                &:last-child {
                  @media only screen and (max-width: 575px) {
                    margin-bottom: 0px;
                  }
                }

                .form-check-label {
                  font-size: 18px;
                  font-weight: normal;
                  color: $white-color;
                  flex: 0 0 calc(100% - 32px);
                  max-width: calc(100% - 32px);
                }

                .form-check-input {
                  flex: 0 0 20px;
                  max-width: 20px;
                  width: 100%;
                  height: 20px;
                  float: none;
                  margin: 4px 12px 0 0px;
                  border-radius: 0px;

                  &:checked {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    border-radius: 0px;
                  }

                  &:focus {
                    border-color: rgba(0, 0, 0, 0.25);
                    box-shadow: none;
                    outline: none;
                  }
                }
              }
            }
          }

          .submit_btn {
            text-align: center;
          }
        }
      }
    }
  }
}
