@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.protokoll_section {
  padding: 50px 0px;

  .user_detail {
    position: relative;

    .protokoll_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      a {
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1.2;
        font-size: 24px;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
          font-size: 20px;
        }

        &:hover {
          color: $default-color;
        }
      }
    }
  }

  .protokoll_card {
    padding: 60px;
    border-radius: 20px;
    box-shadow: $drop-shadow;
    background-color: $dark-bg;

    @media only screen and (max-width: 767px) {
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }

    .protokoll_card_inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -40px;

      @media only screen and (max-width: 991px) {
        margin: 0px;
      }

      .protokoll_form {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 40px;

        @media only screen and (max-width: 991px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0px;
        }

        form {
          row-gap: 24px;

          .form-label {
            margin-bottom: 10px;
            font-family: "Brother 1816";
            font-size: 16px;
            line-height: 23px;
            color: $white-color;
            display: block;
          }

          .form-control {
            font-family: "Brother 1816";
            border: 1px solid #eeeeee;
            border-radius: 10px;
            height: 50px;
            font-size: 16px;
            line-height: 23px;
            color: $white-color;
            background-color: $black-color;

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          .form-check-input {
            &:focus {
              border-color: $primary-color;
              outline: none;
              box-shadow: none;
            }
            &:checked {
              background-color: $primary-color;
              border-color: $primary-color;
            }
          }
          .form-check-label{
            color: $white-color;
          }

          textarea {
            &.form-control {
              height: 150px;

              @media only screen and (max-width: 767px) {
                height: 50px;
              }
            }
          }

          .type-select {
            position: relative;
            display: inline-block;
            display: block;
            width: 100%;

            @media only screen and (max-width: 767px) {
              margin: 0 auto 10px;
            }

            .dropdown-toggle4,
            .dropdown-toggle5 {
              border: 1px solid #eeeeee;
              width: 100%;
              padding: 16px 15px;
              border-radius: 10px;
              color: $white-color;
              background-color: $black-color;
              font-size: 16px;
              line-height: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &.show {
                border-radius: 10px 10px 0px 0px;

                &::after {
                  transform: rotate(180deg);
                }
              }

              &::after {
                border: 0px;
                margin: 0px;
                background-image: url(../../../../assets/images/icons/arrow-down.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: "";
                width: 16px;
                height: 10px;
                vertical-align: unset;
                transition: 0.5s all ease-in-out;
              }

              &:active {
                border-color: #eeeeee;
                color: $white-color;
                background-color: $black-color;
              }
            }

            .dropdown-menu {
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              z-index: 1000;
              display: none;
              padding: 0;

              &.show {
                display: block;
                border-radius: 0 0 10px 10px;
                border-color: #eeeeee;
                max-height: 200px;
                overflow: scroll;
                background-color: $black-color;
              }

              &::-webkit-scrollbar {
                display: none;
              }

              li {
                &:last-child {
                  .dropdown-item {
                    &:hover {
                      border-radius: 0 0 10px 10px;
                    }
                  }
                }
              }

              .dropdown-item {
                color: $white-color;
                font-size: 16px;
                line-height: 1;
                padding: 16px 15px;

                &:hover {
                  background-color: $primary-color;
                  color: $white-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
