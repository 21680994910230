@import "../../assets/css/typography.scss";
@import "../../assets/css/variables.scss";

.hideFaqData {
  display: none;
}

.faq_section {
  padding: 0px 0px 50px;

  .user_detail {
    position: relative;
    border-bottom: 1px solid $border-color;

    .faq_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .accordion {
    margin-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 30px;
    }

    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }

    .accordion-item {
      border: 1px solid $primary-color;
      margin-bottom: 20px;
      box-shadow: $drop-shadow;
      border-radius: 5px;

      .accordion-header {
        .accordion-button {
          border-radius: 5px;
          font-weight: 500;
          background-color: $dark-bg;
          color: $white-color;

          &:not(.collapsed) {
            color: $white-color;
            background-color: $primary-color;
            border-radius: 5px 5px 0px 0px;

            &::after {
              filter: brightness(0) invert(1);
            }
          }

          &::after {
            background-image: url(../../assets/images/icons/arrow-down.svg);
            background-size: auto;
            width: 14px;
            height: 8px;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          background-color: $dark-bg;
          border-radius: 0 0 5px 5px;
          .faq_detail {
            margin-top: 10px;

            p {
              line-height: 1.4;
            }

            a {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
