@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

  .apply_course_search_section {
        padding: 0px 0px 50px;

        .user_detail {
            position: relative;

            .apply_course_title {
                padding: 41px 0px 43px 0px;

                @media only screen and (max-width: 767px) {
                    padding: 25px 0px 15px;
                }

                h2 {
                    text-transform: uppercase;
                    font-weight: 500;

                    @media only screen and (max-width: 1199px) {
                        font-weight: 700;
                        max-width: calc(100% - 81px);
                    }
                }
            }
        }

        .apply_course_search {
            form {
                row-gap: 24px;

                .form-label {
                    margin-bottom: 10px;
                    font-family: 'Brother 1816';
                    font-size: 16px;
                    line-height: 23px;
                    color: $form-lable-color;
                }

                .form-control {
                    font-family: 'Brother 1816';
                    border: 1px solid #EEEEEE;
                    border-radius: 10px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 23px;
                    color: $black-color;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }
    }
