@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.overview_absence_section {
  padding: 0px 0px 50px;

  .user_detail {
    .overview_absence_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .overview_absence {
    .overview_absence_inner {
      .overview_absence_cards {
        margin: 0 -18px;

        .overview_absence_card {
          margin-bottom: 36px;
          padding: 0 18px;

          .overview_absence_card_inner {
            box-shadow: $drop-shadow;
            background-color: $dark-bg;
            border-radius: 20px;
            padding: 20px 28px;
            height: 100%;

            .overview_absence_card_title {
              padding: 0 0 14px 0;
              margin-bottom: 14px;
              border-bottom: 1px solid #e4e4e4;
              display: flex;
              align-items: center;
              justify-content: space-between;

              h3 {
                text-transform: uppercase;
                font-weight: 500;
                color: $primary-color;
                flex: 0 0 calc(100% - 40px);
                max-width: calc(100% - 40px);

                span {
                  color: $white-color;
                  display: inline-block;
                }
              }

              .delete-icon {
                flex: 0 0 20px;
                max-width: 20px;
                width: 100%;
                height: 28px;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
}
