@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.apply_course_section {
  padding: 0px 0px 50px;

  .video_list_modal {
    .modal {
      .modal-dialog {
        max-width: 65%;

        @media only screen and (max-width: 1199px) {
          max-width: 75%;
        }

        @media only screen and (max-width: 767px) {
          max-width: 95%;
        }

        .modal-header {
          a {
            width: 24px;
            height: 24px;

            &.btn-close {
              opacity: 1;
              background: transparent;
              background-image: none;
            }
          }
        }

        .modal-body {
          .date_time_title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 30px;
            margin-bottom: 25px;

            @media only screen and (max-width: 767px) {
              justify-content: center;
            }

            .date_time {
              position: relative;

              @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 20px;
                margin-bottom: 10px;
              }

              &::after {
                position: absolute;
                top: 0;
                right: -15px;
                width: 1px;
                height: 100%;
                content: "";
                display: block;
                background-color: $border-color;

                @media only screen and (max-width: 767px) {
                  width: 50%;
                  height: 1px;
                  top: auto;
                  bottom: -5px;
                  right: 0px;
                  transform: translate(-50%, 0px);
                }
              }

              .date {
                p {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 5px;

                  @media only screen and (max-width: 767px) {
                    margin-bottom: 0px;
                  }
                }
              }

              .time {
                p {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }

            .title_detail {
              @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
              }

              h3 {
                font-weight: 500;
                margin-bottom: 5px;
              }
            }
          }

          .video_block {
            height: 45vh;
          }
        }
      }
    }
  }

  .user_detail {
    position: relative;

    .apply_course_title {
      padding: 41px 0px 43px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .apply_course_inner {
    .apply_course_card {
      @media only screen and (max-width: 767px) {
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
      }

      .apply_course_card_inner {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1607843137);
        border-radius: 20px;
        padding: 20px 28px;
        height: 100%;

        .apply_course_card_title {
          padding-bottom: 23px;
          margin-bottom: 14px;
          border-bottom: 1px solid #e4e4e4;

          h2 {
            text-transform: uppercase;
            font-weight: 500;
          }
        }

        .apply_course_detail {
          .apply_course_list {
            .apply_course_item {
              margin-bottom: 19px;
              font-size: 16px;
              font-weight: normal;
              color: $white-color;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              gap: 10px;

              @media only screen and (max-width: 767px) {
                margin-bottom: 14px;
                font-size: 14px;
              }

              span {
                text-transform: uppercase;
              }
            }
          }

          h3 {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #e4e4e4;
            font-weight: 500;
            text-transform: uppercase;
          }

          .space_for_male,
          .space_for_female {
            margin-bottom: 20px;

            p {
              margin-bottom: 10px;
              text-transform: uppercase;
            }

            .select_slot {
              form {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .form-check {
                  .form-check-input {
                    width: 25px;
                    height: 25px;
                    background-color: green;
                    border: none;
                    border-radius: 0px;
                    cursor: pointer;

                    &:checked {
                      border: none;
                      border-radius: 0px;
                      outline: none;
                      box-shadow: none;
                      background-color: $primary-color;
                      background-image: none;
                    }

                    &:focus {
                      border: none;
                      outline: none;
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .apply_course_btn {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;

      a {
        text-transform: uppercase;
      }
    }
  }

  .apply_data_table {
    .table {
      .table-group-divider {
        border-top: 2px solid $primary-color;
      }

      th {
        white-space: nowrap;
        background-color: $dark-bg;
        color: $white-color;
      }

      tbody {
        tr {
          td {
            vertical-align: middle;
            background-color: $dark-bg;
            color: $white-color;
            white-space: nowrap;

            // &:last-child {
            //   display: flex;
            //   gap: 10px;
            // }

            .ok_btn {
              font-size: 14px;
              padding: 10px 20px;

              line-height: 1;
              border-radius: 50px;
              transition: 0.5s all ease-in-out;
              color: $white-color;
              background-color: $primary-color;
              border: 2px solid $primary-color;

              &:hover {
                color: $white-color;
                background-color: $btn-primary-hover;
                border: 2px solid $btn-primary-hover;
              }
            }

            .notok_btn {
              font-size: 14px;
              padding: 10px 20px;
              line-height: 1;
              border-radius: 50px;
              margin-top: 5px;
              transition: 0.5s all ease-in-out;
              color: $white-color;
              background-color: $secondary-color;
              border: 2px solid $secondary-color;

              &:hover {
                color: $white-color;
                background-color: $btn-secondary-hover;
                border: 2px solid $btn-secondary-hover;
              }
            }

            select {
              height: 40px;
              border: 1px solid #eeeeee;
              padding: 5px 10px;
              border-radius: 10px;
              color: $white-color;
              background-color: $black-color;
              font-size: 14px;

              &:focus {
                border: 1px solid #eeeeee;
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
