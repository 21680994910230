@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.language_section {
  padding: 50px 0px;

  .language_title {
    margin-bottom: 24px;

    h2 {
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .language_inner {
    .language_card {
      padding: 60px;
      box-shadow: 0px 0px 20px #00000029;
      border-radius: 20px;
      background-color: $dark-bg;

      @media only screen and (max-width: 767px) {
        padding: 0;
        box-shadow: none;
        border-radius: 0px;
      }

      .language_selector {
        .custom_lable {
          p {
            color: $white-color;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }

        .custom-select {
          position: relative;
          display: inline-block;
          display: block;
          width: 100%;

          @media only screen and (max-width: 767px) {
            margin: 0 auto 10px;
          }

          .dropdown-toggle {
            border: 1px solid #eeeeee;
            width: 100%;
            padding: 16px 15px;
            border-radius: 10px;
            color: $white-color;
            background-color: $black-color;
            font-size: 16px;
            line-height: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.show {
              border-radius: 10px 10px 0px 0px;

              &::after {
                transform: rotate(180deg);
              }
            }

            &::after {
              border: 0px;
              margin: 0px;
              background-image: url(../../../assets/images/icons/arrow-down.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              content: "";
              width: 16px;
              height: 10px;
              vertical-align: unset;
              transition: 0.5s all ease-in-out;
            }

            &:active {
              border-color: #eeeeee;
              background-color: $black-color;
              color: $white-color;
            }
          }

          .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 1000;
            display: none;
            padding: 0;

            &.show {
              display: block;
              border-radius: 0 0 10px 10px;
              border-color: #eeeeee;
              background-color: $black-color;
            }

            li {
              &:last-child {
                .dropdown-item {
                  &:hover {
                    border-radius: 0 0 10px 10px;
                  }
                }
              }
            }

            .dropdown-item {
              color: $white-color;
              font-size: 16px;
              line-height: 1;
              padding: 16px 15px;

              &:hover {
                background-color: $primary-color;
                color: $white-color;
              }
            }
          }
        }

        .language_btn {
          margin-top: 30px;

          a {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
