@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.exercise_section {
  padding: 0px 0px 50px;

  .user_detail {
    .exercise_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 36px 0px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 30px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
        }
      }
    }
  }

  .exercise_listing {
    .exercise_inner {
      .exercise_date {
        box-shadow: $drop-shadow;
        background-color: $dark-bg;
        padding: 60px;
        border-radius: 20px;
        margin-bottom: 40px;

        @media only screen and (max-width: 1199px) {
          padding: 30px;
        }

        @media only screen and (max-width: 575px) {
          padding: 30px 20px;
        }

        .row {
          row-gap: 15px;

          .form-check {
            padding-left: 0px;

            .form-check-input {
              width: 20px;
              height: 20px;
              margin: 4px 10px 0px 0px;
              border-radius: 0px;

              &:checked {
                background-color: $primary-color;
                border-color: $primary-color;
                border-radius: 0px;
              }

              &:focus {
                border-color: $border-color;
                box-shadow: none;
                outline: none;
              }
            }

            .form-check-label {
              font-size: 18px;
              color: $white-color;
            }
          }

          .form-input-column {
            flex: 0 0 calc(100% - 220px);
            max-width: calc(100% - 220px);

            @media only screen and (max-width: 991px) {
              flex: 0 0 100%;
              max-width: 100%;
            }

            .form-input {
              .form-control {
                border: 1px solid #d6d6d6;
                width: 100%;
                padding: 13px 25px;
                background-color: $black-color;
                border-radius: 50px;
                color: $white-color;
                font-size: 18px;
                line-height: 1.2;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:focus {
                  border: 1px solid #d6d6d6;
                  box-shadow: none;
                  outline: none;
                }
              }
            }
          }

          .active-btn-column {
            flex: 0 0 220px;
            max-width: 220px;

            @media only screen and (max-width: 991px) {
              flex: 0 0 100%;
              max-width: 100%;
            }

            .active_btn {
              a {
                width: 100%;
                padding: 16px 10px;
              }
            }
          }
        }
      }

      .exercise_card {
        padding-top: 40px;
        border-top: 1px solid $border-color;

        .exercise_name {
          h2 {
            margin-bottom: 20px;
            text-align: center;

            span {
              &.exercise_type {
                font-weight: 500;
              }

              font-weight: 400;
            }
          }
        }

        .exercise_days {
          form {
            margin: 0 -10px;
            justify-content: center;
            row-gap: 20px;

            .exercise_day_column {
              padding: 0 10px;

              .exercise_day {
                border-radius: 20px;
                box-shadow: $drop-shadow;
                padding: 20px 10px;
                background-color: $dark-bg;

                h3 {
                  text-align: center;
                  margin-bottom: 10px;
                }

                .form-check {
                  padding-left: 0px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .form-check-input {
                    width: 20px;
                    height: 20px;
                    margin: 2px 10px 0px 0px;
                    border-radius: 50px;
                    float: none;

                    &:checked {
                      width: 20px;
                        height: 20px;
                      background-color: $primary-color !important;
                      border-color: $primary-color !important;
                    }

                    &:focus {
                      border-color: $border-color;
                      box-shadow: none;
                      outline: none;
                    }
                  }
                  .form-check-label{
                    color: $white-color;
                  }
                }
              }
            }
          }
        }
      }

      .exercise_video {
        margin-top: 40px;
        .exercise_video_inner {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
      }
    }
  }
}
