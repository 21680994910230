@import "../../../assets/css/typography.scss";
@import "../../../assets/css/variables.scss";

.chat_list_section {
  padding: 0px 0px 50px;

  .chat_list_hide {
    display: none;
  }

  .user_detail {
    position: relative;
    border-bottom: 1px solid $border-color;

    .courses_title {
      padding: 41px 0px 24px 0px;

      @media only screen and (max-width: 767px) {
        padding: 25px 0px 15px;
      }

      h2 {
        text-transform: uppercase;
        font-weight: 500;

        @media only screen and (max-width: 1199px) {
          font-weight: 700;
          max-width: calc(100% - 81px);
        }
      }
    }
  }

  .chat_list {
    margin-top: 40px;

    .chat_list_inner {
      .chat_list_blocks {
        ul {
          display: flex;
          align-items: stretch;
          margin: 0 -15px;
          flex-wrap: wrap;
          row-gap: 15px;

          @media only screen and (max-width: 767px) {
            padding: 0 10px;
          }

          li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;

            @media only screen and (max-width: 767px) {
              padding: 0 10px;
            }

            @media only screen and (max-width: 575px) {
              flex: 0 0 100%;
              max-width: 100%;
            }

            a {
              color: $white-color;
              padding: 1rem 2rem;
              display: block;
              box-shadow: $drop-shadow;
              background-color: $white-color;
              border-radius: 5px;
              transition: 0.5s all ease-in-out;
              height: 100%;
              background-color: $dark-bg;

              &:hover {
                background-color: $primary-color;
                color: $white-color;
              }
            }
          }
        }
      }
    }
  }
}
