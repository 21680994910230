@import "../../../../assets/css/typography.scss";
@import "../../../../assets/css/variables.scss";

.teacher_perfomance_section {
  padding: 50px 0px;

  .personal_info_title {
    padding: 0px 0px 43px 0px;

    h2 {
      text-transform: uppercase;
    }

    @media only screen and (max-width: 767px) {
      padding: 0px 0px 34px 0px;
    }
  }

  .personal_info_card {
    padding: 60px;
    border-radius: 20px;
    box-shadow: $drop-shadow;
    background-color: $dark-bg;

    @media only screen and (max-width: 767px) {
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }

    .personal_info_card_inner {
      display: flex;
      flex-wrap: wrap;

      .info_form {
        flex: 0 0 100%;
        max-width: 100%;

        .info_form_title {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #d6d6d6;

          h3 {
            font-size: 16px;
            text-transform: uppercase;
          }
        }

        .row {
          row-gap: 24px;

          .form-label {
            margin-bottom: 10px;
            font-family: "Brother 1816";
            font-size: 16px;
            line-height: 23px;
            color: $white-color;
          }

          .form-control {
            font-family: "Brother 1816";
            border: 1px solid #eeeeee;
            border-radius: 10px;
            height: 50px;
            font-size: 16px;
            line-height: 23px;
            color: $white-color;
            background-color: $black-color;

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          textarea {
            &.form-control {
              height: 150px;

              @media only screen and (max-width: 767px) {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}
